export default {
	"Membership": "Status",
	"MEMBERSHIP": "SUBSCRIPTION",
	"BRONZE": "BASIC",
	"after_here": "",

	"CLICK TO ACTIVATE - desktop": "CLICK TO ACTIVATE",

	"logout_confirm_title": "Log out", 

	"card_number_require": "Card number is required",
	"card_number_invalid": "Card number is invalid",
	"card_name_require": "Cardholder name is required",
	"card_name_invalid": "Cardholder name in invalid",
	"card_cvc_require": "CVC is required",
	"card_cvc_invalid": "CVC in invalid",
	"card_expiry_require": "Card expiry date is required",
	"card_expiry_invalid": "Card expiry date in invalid",

	"Your payment has been cancelled": "Your payment has been cancelled",
	"Something went wrong, please try again in 10 minutes": "Something went wrong, please try again in 10 minutes",
	"Connect with Yahoo": "Sign up with Yahoo",
	"Connect with Microsoft": "Connect with Hotmail",
	"Or turn back": "Go back",
	"Complete your registration": "Complete your registration",
	"rg_terms": "Terms and Conditions",
	"rg_privacy": "Privacy policy",
	"rg_cookies": "Cookies",

	"Cookie": "Cookie",
	"Responsible Party": "Responsible Party",
	"Category": "Category",
	"Cookie Name": "Name",
	"Purpose": "Purpose",
	"Lifespan": "Lifespan",
	"Type of Cookie": "Type of Cookie",
	"Strictly necessary": "Strictly necessary",
	"Functional": "Functional",
	"Performance": "Performance",
	"Advertising": "Advertising",
	"cookie-policy": "cookie-policy",
	"cookie policy": "cookie policy",

	"We Care About Your Privacy": "We Care About Your Privacy",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.",
	"Accept All": "Accept All",
	"Cookies Settings": "Cookies Settings",
	"About your privacy": "About your privacy",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.",
	"You will be able to change your set up at any time from our :cookies_policy page": "You will be able to change your set up at any time from our :cookies_policy page",
	"Accept all": "Accept all",
	"Manage Consent Preferences": "Manage Consent Preferences",
	"Technical (Mandatory)": "Technical (Mandatory)",
	"Performance (Monitor website performance)": "Performance (Monitor website performance)",
	"Functional (User experience improvement)": "Functional (User experience improvement)",
	"Advertising (Marketing measurement)": "Advertising (Marketing measurement)",
    "Advertising (Personalization)": "Advertising (Personalization)",
	"Save Selection": "Save Selection",
	"Reject All": "Reject All",

	"We and our partners process data to provide:": "We and our partners process data to provide:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.",
	"List of partners": "List of partners",
	"Cookie Policy": "Cookie Policy",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.",
	"Delete cookies": "Delete cookies",
	"Save selection": "Save selection",
	"Reject all": "Reject all",

	"{name} hasn't replied to you yet. Send another message!": "{name} hasn't replied to you yet. Send another message!"
}
