export default {
	"Already a member?": "Déjà membre?",
	"Login": "Se connecter",
	"Log out": "Se déconnecter",
	"Your current status": "Ton statut actuel",
	"BASIC": "BASIC",
	"Basic": "Basic",
	"Bronze": "BASIC",
	"Elite": "ÉLITE",
	"VIP": "VIP",
	// "Join Us": "Entra ahora",
	// "Find people near you": "Encuentra gente cerca de ti",
	"Join now": "Créer mon profil",
	"I am a": "Je suis",
	"Email": "Email",
	"It will not be published": "Cela ne sera pas publié",
	"E-mail address": "Entrez votre email",
	"SEARCH NOW": "S'INSCRIRE GRATUITEMENT",
	"I confirm that I am over 18 years old": "Je confirme que j'ai plus de 18 ans",
	// "Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "No lo pienses más y entra al fenómeno social del momento. Cada día miles de personas como tú se unen a nosotros.",
	"Information": "Information",
	"All rights reserved.": "Tous droits réservés.",
	"SUBSCRIPTION": "STATUT",
	"Other": "Autres questions",
	"All fields are required": "Remplir tous les champs",
	"The fields marked in Red are required. Please fill them to continue.": "Les champs en rouge sont obligatoires. Remplis les pour continuer.",
	"If you did not find a suitable answer please contact us": "Si tu ne trouves pas le thèmes approprié, écris nous",
	"Will help us to identify you": "ça nous aidera à t'identifier",
	"Invalid Username": "Nom d'utilisateur incorrect",
	"Subject": "Objet",
	"Tell us how can we help you": "Dis nous comment on peut t'aider",
	"Please enter subject": "S'il te plait tape l'objet",
	"Please enter at least 10 characters": "Tape 10 caractères minimum s'il te plait",
	"Connected to your account": "Connecté à ton compte",
	"Repeat Email": "Répète l'email",
	"Emails did not match": "L'email ne correspond pas",
	"Message": "Message",
	"No Recent Activity": "Pas d'activité récente",

	//route labels
	"Home": "Accueil",
	"Contact": "Contact",
	"Support": "Support",
	"Affiliates": "Affiliés",
	"Privacy": "Confidentialité",
	"Terms": "Conditions",

	//routes uri
	"/login": "/demarrer-session",
	"/contact": "/contact",
	"/support": "/support",
	"/affiliates": "/Programme-d-adhesion",
	"/privacy": "/Confidentialite",
	"/terms-and-conditions": "/termes-et-conditions-d-utilisation",
	"/cookie-policy": "/politique-des-cookies",
	"/subscription/exit": "/souscription/sortie",
	"/purchase-confirmation": "/confirmation-achat",
	"/page/:page_name": "/page/:page_name",

	"/dashboard": "/accueil",
	"/mobile-dashboard": "/ecran-de-mobil",
	"/activate-profile/:token": "/activer-profil/:token",
	"/edit-profile": "/mon-profil",
	"/profile/:name": "/profil/:name",
	"/my-picture": "/mes-photos",
	"/messages/inbox": "/tchat/boite-de-reception",
	"/messages/trash": "/tchat/corbeille",
	"/messages/send/:name": "/tchat/envoyer/:name",
	"/visitor": "/qui-m-a-vu",
	"/favorite/my": "/mes-favoris/moi",
	"/favorite/others": "/mes-favoris/autres",
	"/search": "/chercher-profils",
	"/search-result": "/resultado-busqueda",
	"/settings": "/parametres",
	"/validate-new-email-address/:token": "/verifier-nouvelle-adresse-email/:token",
	"/subscription": "/souscription",
	"/credits": "/credits",
	"/elite": "/elite",
	"/faq": "/foire-aux-questions",
	"/delete-account": "/compte-efface",

	"/exclusive-promotion": "/promotion-exclusive",
	"/account-deleted": "/compte-supprime",
	"/logged-out": "/session-fermee-reussie",

	//login
	"Login": "Se connecter",
	"Enter username or Email": "Tape ton nom d'utilisateur ou ton email",
	"Enter Password": "Tape ton mot de passe",
	"Username": "Nom d'utilisateur",
	"Nickname": "Pseudo",
	"Password": "Mot de passe",
	"I forgot my password": "J'ai oublié mon mot de passe",
	"Password Reminder": "Me rappeler le mot de passe",
	"Enter you email": "Tape ton email",
	"Your email address": "Ton email",
	"Don't have an account?": "Tu n'as pas déjà un compte",
	"REGISTER": "S'INSCRIRE",
	"Password sent to your email": "Mot de passe envoyer à ton email",
	"This account has not been validated yet": "Ce compte n'a pas encore été activé",
	"LOGIN USING YOUR CREDENTIALS": "INTRODUIS TES DONNEES",
	"FORGOT YOUR PASSWORD?": "MOT DE PASSE OUBLIE ?",
	"Login failed": "Échec de la connexion",
	"Username or password is incorrect": "Nom d'utilisateur ou mot de passe incorrect",

	"We're sorry!": "Nous sommes désolés",
	//registration
	"You're almost done!": "On a presque terminé !",
	"Please fill your additional account information": "Il manque encore quelques informations",
	"Just one step left!": "Plus qu'une étape!",
	"Let us learn more about you. Fill in your personal data": "Permets nous de mieux te connaître: Complète tes données personnelles",
	"Activate your account now.": "Active ton compte maintenant !",
	"Activate your account now": "Active ton compte maintenant !",
	"Please confirm your e-mail address": "Confirme ton email s'il te plait",
	"Please fill in the following form to join {site_name} for free": "S'il te plait complète le formulaire suivant pour t'inscrire gratuitement à {site_name}",
	"CONTINUE": "CONTINUER",

	"By clicking on the continue button I agree with the": "En cliquant sur \“Continuer\” je suis d'accord avec",
	"terms": "conditions",
	"and cookies": "et les cookies",

	"This site is protected by reCaptcha and the Google": "Ce site est protégé par reCAPTCHA. Les",
	"Privacy Policy": "règles de confidentialité",
	"and": "et les",
	"Terms of Service": "conditions d'utilisation",
	"apply.": "de Google s'appliquent",

	"Personal description": "Description personnelle",
	"optional": "optionnel",
	"FINALIZE": "TERMINER",
	"We have just sent you a confirmation e-mail at": "Nous venons de t'envoyer un email de confirmation à",
	"To start using our website, please click on the activation link in this email.": "Pour commencer à utiliser notre site web, clique sur le lien d'activation dans ce mail.",
	"Click here to verify your email": "Clique ici pour vérifier ton email",
	"CLICK TO ACTIVATE": "CLIQUER POUR VÉRIFIER",
	"CLICK TO ACTIVATE - desktop": "CLIQUER POUR VÉRIFIER",
	"VERIFY MY EMAIL": "VALIDER MON EMAIL",
	"Not received any email yet?": "Tu n'as pas reçu de mail ?",
	"Please check your": "Vérifie ta boite de",
	"spam": "les mails indésirables",
	"box and wait at least 10 minutes or re-send activation": ", attends au moins 10min puis demande le renvoie de l’email.",
	"Enter Nickname": "Entre ton Pseudo",
	"Enter Your Name": "Entre ton Prénom",
	"Password Required": "Mot de passe nécessaire",
	"Enter valid Email": "Insérer un mail valide",
	"Please enter at least 5 characters": "5 caractères minimum",
	"Enter strong password": "Entre un mot de passe difficile",
	"Email not available": "Email non disponible",
	"Nickname not available": "Pseudo non disponible",
	"Maximum 4 number allowed": "Maximum 4 chiffres autorisés",
	"Name should not contain any space, number or special character": "Le Prénom ne doit pas contenir d'espace, de chiffre ou de caractères spéciaux",
	"Nickname should not contain any space or special characters": "Le Pseudo ne doit pas contenir d'espace ou de caractères spéciaux",
	"Your activation mail has been sent": "Un email d'activation a été envoyé",
	"Complete your profile in a minute and start contacting people": "Complète ton profil en quelques minutes et contacte du monde",
	"Complete your profile": "Complète ton profil",
	"Create Profile": "Créer Profil",
	"Your description": "Ta desription",
	"Confirm your Email": "Confirme ton email",
	"We have sent you an email.": "Nous t'avons envoyé un email.",
	"Check your email to complete your registration": "Vérifie ta boite de réception pour compléter l'inscription",
	"and be able to speak with other users.": "et pouvoir parler avec d'autres utilisateurs.",
	"If you can not find it look it up in the spam or": "Si tu ne le trouves pas, vérifie dans tes",
	"If you have not received it select one of the following options": "Si tu ne l'as pas reçu, choisi une des options suivantes",
	"junk folder": "spam",
	"Change Email": "Changer l'email",
	"Resend Email": "Renvoyer l’email",
	"Resend": "Renvoyer",
	"New Email": "Nouvel email",
	"Your activation key is invalid": "Ton code d'activation est invalide",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Veuillez inclure un '@' dans l'adresse e-mail. '{email}' n'a pas de '@'.",

	//public pages
	"privacy": "Confidentialité",
	"terms-and-conditions": "termes-et-conditions-d-utilisation",
	"about-us": "om-oss",

	//forms
	"Name": "Prénom",
	"Marital Status": "Statut marital",
	"Date of Birth": "Date de naissance",
	"Country": "Pays",
	"Location": "Région",
	"Province": "Région",
	"City": "Ville",
	"Save Changes": "Sauvegarder les changements",
	"Select from list": "Choisis dans la liste",
	"Day": "Jour",
	"Month": "Mois",
	"month": "Mois",
	"Year": "Année",
	"Prefer not to say": "Préfère ne pas le dire",
	"Only profiles with photo": "Seulement les profils avec photo",
	"This field is required": "Ce champ est nécessaire",
	"Please update your profile": "Actualise ton profil",
	"Profile successfully updated": "Profil mis à jour avec succès",
	"The fields marked in red are required. Please fill them to continue.": "Les champs en rouge sont obligatoires. Remplis les pour continuer.",
	"Characters left": "Caractères restants",
	"Image attached": "Image jointe",
	"The message field is required.": "Le message doit contenir un objet.",
	"WITH PICTURE": "AVEC PHOTO",
	"all age groups": "Sélectionne l'âge",
	"EDIT DESCRIPTION": "EDITER LA DESCRIPTION",
	"DESCRIPTION": "DESCRIPTION",
	"FEATURING": "PERSONNALITE",
	"Please Select": "S'il te plait sélectionne",
	"Emails do not match": "L'email ne correspond pas",
	"Your request was succesfully sent!": "Ta demande a été envoyée avec succès!",
	"Or send us a message directly": "Ou envoyez-nous un email directement",

	//user pages
	"My Profile": "Mon Profil",
	"Add Photos": "Ajouter des photos",
	"Messages": "Messages",
	"Favorites": "Favoris",
	"Search": "Recherche",
	"Settings": "Paramètres",
	"Visits": "Visites",
	"Likes": "Favoris des autres",
	"Membership": "Statut",
	"Vip Members": "Utilisateurs",

	"Account activation successful": "Activation du compte réussie",
	"My chats": "Mes tchats",

	//user home
	"Quick Search": "Recherche rapide",
	"More Options": "Plus d'options",
	"I am a": "Je suis",
	"Looking for": "Cherche",
	"Age": "Age",
	"Search for": "Chercher",
	"All age groups": "Tout âge",
	"Popular Members": "Membres populaires",
	"New Members": "Nouveaux Membres",
	"Find a Match": "Faire une recherche",
	"Recent Activity": "Activité récente",
	"Add Photo": "Ajouter photo",
	"Back": "Retour",
	"Become Elite": "Améliorer",
	"Upgrade": "AMÉLIORER",

	"likes": "COUP DE FOUDRE",
	"EDIT MY PROFILE": "EDITER MON PROFIL",
	"CONFIGURATION": "CONFIGURATION",
	"ONLINE SUPPORT": "SUPPORT EN LIGNE",

	//user message
	"Trash": "Corbeille",
	"Delete Selected": "Effacer la sélection",
	"No Messages Available": "Pas de message disponible",
	"No Messages selected": "Pas de message sélectionné",
	"Back to inbox": "Revenir à la boite de réception",
	"Back to messages": "Retourner aux messages",
	"Select all": "Tout sélectionner",
	"Deselect all": "Tout désélectionner",
	"Next Page": "Page suivante",
	"Prev Page": "Page précédente",
	"Type your message here": "Ecris ton message ici",
	"Send message": "Envoyer le message",
	"Send Icebreaker": "Envoyer le Brise-glace",
	"VIEW PROFILE": "VOIR PROFIL",
	"DELETE": "EFFACER",
	"Chat with": "Tchate avec",
	"Chat": "Tchate",

	//user favorites
	"My Favorites": "Mes favoris",
	"No likes": "Il n'y a pas de favoris",
	"Favorite added successfully": "Favori ajouté avec succès",
	"Favorite deleted successfully": "Favori supprimé avec succès",
	"Visit Profile": "Voir le profil",

	//user visits
	"My Profile Visitors": "Visites sur mon profil",
	"No Visitors": "Aucun visiteur",

	//user photos
	"My pictures": "Mes photos",
	"See my public profile": "Voir ton profil public",
	"Profile photo": "Photo de Profil",
	"Profile Picture": "Photo de Profil",
	"2MB or lower.": "2MB ou moins.",
	"2MB or lower": "2MB ou moins",
	"Nudes not allowed in this category.": "Les photos nues ne sont pas autorisées dans cette catégorie.​",
	"PHOTO ALBUM": "ALBUM PHOTOS",
	"Allowed images 2 megabytes or less": "Images de 2 megabytes ou moins autorisées",
	"Add picture": "Ajouter une photo",
	"Main reasons for rejection": "RAISONS PRINCIPALES DE REJET",
	"Are you sure?": "tu es sûr ?",
	"Are you sure you want to delete this image?": "Sûr que tu veux effacer cette photo?",
	"Close": "Fermer",
	"Confirm": "Confirmer",
	"Attaching image": "En train de mettre l'image en pièce jointe",
	"The message must be at least 2 characters.": "Le message doit contenir au moins 2 caractères.",
	"Deleting...": "En train d'effacer...",
	"Pictures of underage people": "Photos de mineurs",
	"Personal data is visible": "Avec des données personnelles",
	"Fake or stolen pictures": "Photos fausses ou photos volées",
	"Different gender to profile": "Différent sexe que le profil",
	"Group pictures": "Photos de groupe",
	"Weapons, drugs, violence": "Armes, drogues, violence",
	"No people visible": "Il n'y a pas de personne",
	"Info": "Info",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Nous vérifions tes photos. Quand elles auront été validées, elles seront visibles par les autres utilisateurs.",

	//user profiles
	"Profile": "Profil de",
	"yrs": "ans",
	"years": "ans",
	"yr from": "années de",
	"Physique": "Physique",
	"Photos": "Photos",
	"No Photos": "Sans photo",
	"About Me": "A propos de moi",
	"Characteristics": "Caracteristiques",
	"Prefers": "PRÉFÉRENCES",
	"Relationship": "Relation",
	"Shape": "Corps",

	//user edit profile
	"DESCRIBE YOURSELF": "DÉCRIS-TOI",
	"GENERAL": "GÉNÉRAL",
	"(Will be verified)": "(sera vérifié par nos modérateurs)",
	"Sex": "Sexe",
	"Seeking": "Cherche",
	"Finish your profile": "Un instant",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Bienvenu ! Comme votre profil est nouveau, complétez-le pour avoir une visibilité complète.",

	//search profile
	"Advanced Search": "Recherche avancée",
	"OPEN TO": "POUR",
	"PREFERENCES": "PRÉFÉRENCES",
	"CHARACTER": "CARACTÈRE",
	"Search Result": "Résultats de recherche",
	"No Member found": "Pas de membre trouvé",
	"Change Search": "Modifier la recherche",
	"Hair Color": "Cheveux",
	"Eye Color": "Yeux",
	"Body Type": "Silhouette",
	"Height": "Taille",
	"Weight": "Poids",
	"Smoker": "Fumeur",
	"Ethnicity": "Ethnie",
	"From": "de",
	"To": "A",

	//settings
	"Change E-mail address": "Changer l'adresse email",
	"Your email address has been successfully updated": "Ton adresse email a été mise à jour avec succès",
	"Password": "Mot de passe",
	"Account Status": "État Du Compte",
	"Cancel": "Annuler",
	"Delete Account": "Supprimer le compte",
	"Notifications": "Notifications",
	"Receive all notifications individually": "Recevoir toutes les notifications",
	"Receive one daily email will all my notifications": "Les recevoir groupés dans un email quotidien",
	"Receive a weekly email with all my notifications": "Les recevoir groupés dans un email hebdomadaire",
	"I don\'t want to receive any notifications": "Je ne veux pas recevoir de notifications",
	"(we will send you a verification email)": "(tu vas recevoir un email de vérification)",
	"Current Password": "Mot de passe actuel",
	"New Password": "Nouveau mot de passe",
	"Enter valid email": "Insérer un mail valide",
	"Repeat New Password": "Répéter le nouveau mot de passe",
	"Save Settings": "Changer les paramètres",
	"Passwords should match": "Le mot de passe doit coincider",
	"Password length should be minimum 5 characters": "Le mot de passe doit contenir au moins 5 caractères",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "Atention: ton nouvel email doit être vérifié en cliquant sur le lien que nous venons de t'envoyer.",
	"I want to receive notifications by email": "Je veux recevoir les notifications par email",
	"Status": "Status",
	"Renewal": "Renouveler",
	"Auto renewal Canceled": "Renouvellement automatique annulé",
	"Delete profile": "Effacer profil",
	"Your account has been deleted. You will be redirected.": "Ton compte a été effacé. Tu vas être redirigé.",
	"Successfully updated": "Configuration actualisée avec succès",
	"Current account status": "État Actuel Du Compte",
	"Are you sure you want to cancel your subscription?": "Êtes-vous sûr de vouloir annuler votre abonnement?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Votre abonnement a été annulé avec succès et ne sera pas renouvelé automatiquement",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "Por motivos técnicos, la cancelación del renuevo del abono de prueba, deberá realizarse pasadas 8 s de la adquisición. Para cualquier otro tipo de abono, la cancelación deberá realizarse pasadas 8 s de la adquisición y antes de 48 s de su vencimiento.",
	"Profile deleted. Redirecting...": "Profil effacé. redirection en cours ...",
	"*Active subscriptions deleted this way will not be reimbursed": "*Les abonnements en cours effacés de cette manière ne seront pas remboursés.",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Es-tu sûr que tu veux effacer * ton compte ? Tes informations seront effacées définitivement.",
	"Settings successfully updated": "Paramètres modifiés avec succès",
	"Disable Notification": "Désactiver les notifications",
	"The notifications have been successfully disabled.": "Les notifications ont été désactivées avec succès.",
	"Canceled Auto-Renewal": "Auto-Renouvellement Annulé",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Seuls les membres VIP peuvent contacter plus de 5 utilisateurs par jour.",
	"Upgrade your status": "Améliore ton statut",
	"Enjoy our exclusive benefits and make new contacts": "Profite de nos avantages exclusifs et contacte pour moins de 0.03E par jour",
	"Users Online": "Utilisateurs Connectés",
	"Discreet Billing. {site_name} will not appear in the invoice": "Facturation discrète, {site_name} n'apparaitra pas sur la facture",
	"Month ::: Months": "Mois ::: Mois",
	"3 days trial": "ESSAI de 3 JOURS",
	"Try 3 days": "Acheter maintenant",
	"MEMBERSHIP": "ABONNEMENT",
	"Select": "Acheter",
	"Send messages for 3 consecutive days": "Envoie des messages pendant 3 jours",
	"Only one trial subscription per user": "Seulement un essai par utilisateur",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "La souscription sera automatiquement renouvelée au prix d'une des souscriptions suivantes en se basant sur le pack choisi ci-dessus",
	"for": "pour",
	"For full access to all details of events, including how to cancel it,": "Pour voir tous les détails des conditions de renouvellement mais aussi comment l’annuler,",
	"click here": "cliquer ici",
	"for terms and conditions,": "Pour voir les termes et conditions générales,",
	"All profiles are reviewed and moderated by our team.": "Tous les profils sont vérifiés et modérés par notre équipe.",
	"CURRENT PLAN": "SOUSCRIPTION ACTUELLE",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Basique (sans bonus d'essai), tu n'as actuellement aucun pack actif et tu es un utilisateur BASIC. Pour augmenter considérablement tes chances de draguer et contacter d'autres utilisateurs, tu vas avoir besoin du statut Élite.",
	"MY BENEFITS": "MES AVANTAGES",
	"{discount} cheaper": "économise {discount}",
	"save": "Economise",
	"Create my profile": "Créer mon profil",
	"Read messages": "Lire les messages",
	"Add to Favorites": "Ajouter aux Favoris",
	"Make yourself visible to other users by adding them to favorites": "Fais-toi connaître des autres utilisateurs en les ajoutants en favoris",
	"Photo Album": "Album photos",
	"No fake pictures allowed": "Pas de fausse photo autorisée",
	"Album pictures": "Album photos",
	"Show yourself and increase your possibilities": "Montre toi et augmente tes possibilités",
	"Detailed profile search": "Recherche détaillée de profils",
	"Find exactly what you are looking for": "Rencontre exactement ce que tu cherches",
	"See other user's pics": "Voir les photos d'autres utilisateurs",
	"Choose your favorite": "Choisis celui qui te plait le plus",
	"Reply to and send messages": "Envoie ou réponds aux messages",
	"Make direct contact with up to five users per day": "Contacte directement jusqu'à 5 utilisateurs par jour",
	"Be shown first in searches": "Apparaitre en premier dans les recherches",
	"Your profile will be featured before the rest of the users": "Ton profil va être placé au dessus des autres",
	"Make direct contact with unlimited users": "Contacte directement un nombre d'utilisateurs illimités",
	"Choose payment method": "Choisir le mode de paiement",
	"LESS THAN": "MOINS DE",
	"PER DAY": "PAR JOUR",
	"DISCRETION": "DISCRETION",
	"Our website name will not be disclosed": "Notre nom n’apparaît pas sur la facture",
	"Our name will not be disclosed": "Notre nom n’apparaît pas sur la facture",
	"100% Anonymous.": "100% Anonyme.",
	"Choose your subscription": "Choisi ton abonnement",
	"Recommended": "Recommandé",
	"Most Popular": "Les plus populaires",
	"Secure payments with": "Paiement sécurisé avec ",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Ton message n’a pas été envoyé. Améliore ton statut afin de pouvoir envoyer des messages.",
	"Your purchase has been successful": "Votre achat a été réalisé avec succès",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Pour garantir un service optimal et une utilisation ininterrompue, tous les abonnements se renouvellent automatiquement. Si vous voulez changer la configuration, vous pouvez le faire",
	"here.": "ici.",
	"after_here": "",
	//ad
	"Become a": "Deviens",
	"PREMIUM MEMBER": "PREMIUM",
	"And contact": "Et entre en contact",
	"more people": "avec plus d’utilisateurs",

	"upgrade": "commencer",
	"Boost": "Améliore",
	"boost": "Améliorer",
	"your profile": "ton profil",
	"Start chatting and meet more people": "Tchat et fais de nouvelles rencontres",
	"And get": "Et reçois",
	"more views": "plus de visites",

	"Now +3 extra Credits with each purchase": "Maintenant gagne 3 crédits-cadeaux avec chaque achat",
	"Best Seller": "Le plus vendu",
	"Select your Package": "Choisissez votre pack ",
	"Less than {amount} per credit": "Moins que {amount} par crédit",
	"Less than": "Moins que",
	"per credit": "par crédit",
	"Free credits": "Crédits gratuits",
	"per purchase": "pour chaque achat",
	"Premium Status doesn’t allow free messages.": "Le mode premium ne permet pas d'envoyer de messages gratuit.",
	"Chat now": "Chat maintenant",
	"Get Credits": "Obtenir des Crédits",
	"Credit": "Crédit",
	"Credits": "Crédits",
	"My credits": "Mes crédits",
	"{count} Credits left": "{count} Crédits disponibles",
	"Your message has not been sent, you need credits to be able to send messages.": "Votre message n'a pas été envoyé. Vous devez avoir des crédits pour pouvoir envoyer des messages.",
	"Icebreaker": "Brise-glace",
	"Icebreaker today": "Brise-glace aujourd'hui",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Le coût pour envoyer un Brise-glace est de 2 crédits. Le \"brise-glace\" peut s'envoyer seulement pour démarrer une conversation ou en réponse à un autre \"brise-glace\".",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Avec l'acquisition de chaque pack, les utilisateurs procèdent à l'achat unique d'un ensemble de crédits. Les utilisateurs peuvent utiliser ces crédits pour envoyer des messages. Le ratio de crédits par message est de dix pour un, c'est-à-dire que l'utilisateur doit disposer d'au moins dix crédits sur son compte, qui seront utilisés pour envoyer un message. Le coût pour envoyer un Brise-glace est de 2 crédits.",

	"Trial pack": "Paquet d'essai",
	"Only one trial pack per user allowed": "Un seul paquet d'essai autorisé par utilisateur",
	"Used": "Utilisé",

	"Exclusive Promotion": "Promotion Exclusive",
	"Account Deleted": "Compte Supprimé",
	"Log out Successful": "Session fermée",

	"Do you like FREE LIVE SEX?": "T'aimes le SEX EN DIRECTE ET GRATUIT ?",
	"Enter this code to enjoy a 10% discount:": "Profitez d'un rabais de 10% en utilisant le code :",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Ce code est valide pour les 6 prochaines heures lors du premier achat effectué sur le Web.",

	"This account has been deleted.": "Ce compte a été supprimé.",
	"Our algorithm has found a better option in line with your taste.": "Notre algorithme a trouvé une meilleure option en fonction de vos goûts.",
	"You will be redirected in a few seconds.": "Vous allez être redirigé dans quelques secondes.",

	"Has added you to favorites": "T’a ajouté à ses favoris",
	"Is looking at your profile": "Elle regarde ton profil",
	"See profile": "Voir profil",
	"See message": "Voir message",

	"I think I am in love": "Je suis sous le charme.",
	"Hey! I'm winking at you": "Ce clin d'œil est pour toi !",
	"Tell me more about you. Fancy a chat?": "Parle moi plus de toi. On discute ?",
	"Some flowers for you!": "Des fleurs pour toi !",
	"Sending you a kiss": "Je t'envoie un bisou.",
	"Surprise, it\'s me!": "Surprise, c'est moi !",
	"Hi, how are you?": "Salut, comment vas-tu ?",
	"Let's get naughty": "On pourrait s'amuser tous les deux...",

	"Get 5 times the regular response rate. Premium members benefits:": "Profitez de cinq fois plus d’action. Les avantages des membres premium sont :",
	"Appear high up on searches": "Apparaître plus haut dans les recherches",
	"Distinctive badge that increases your visibility": "Insigne distinctif pour augmenter la visibilité",
	"5 Free Icebreakers a day": "5 Brise-glace gratuits par jour",
	"In addition to this, a voucher for a 15% Webcam discount": "En plus de cela, un bonus de réduction de 15% sur les webcams",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Le \"brise-glace\" peut s'envoyer seulement pour démarrer une conversation ou en réponse à un autre \"brise-glace\".",

	"Succesful purchase": "Achat réussi",
	"Your purchase has been successful.": "Votre achat a été effectué avec succès.",
	"It can take a few minutes to update.": "L'attente peut prendre quelques minutes.",
	"You will be redirected.": "Vous serez redirigé.",

	"Changes can take some minutes to update.": "Les changements seront mis à jour dans quelques minutes.",

	"Yearly Package": "Abonnement annuel",
	"Package": "Abonnement",
	"Buy Now": "Acheter maintenant",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "Le statut Elite permet de contacter jusqu’à 5 utilisateurs distincts quotidiennement en leur envoyant des messages de façon illimitée.",

	"Exclusive offer": "Offre exclusive",
	"Welcome": "Bienvenue",
	"Mary from {domain_name} support": "Marie du support {domain_name}",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Bienvenue sur {domain_name}. Nous espérons que vous trouverez ce que vous recherchez.",
	"Here are some Pro Tips to be more successful and meet people:": "Voici quelques astuces pour réussir à mieux rencontrer des gens :",
	"Upload a profile picture to get more connections.": "Téléchargez une photo pour avoir plus d'interactions.",
	"Send an icebreaker to get a conversation easily started.": "Envoyer un brise-glace pour démarrer une conversation plus facilement.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Si vous aimez quelqu'un, n'oubliez pas de l'ajouter à vos favoris pour le lui faire savoir.",
	"And of course, if you like somebody send a message and ask for a date!": "Et bien sûr, si vous aimez quelqu'un, envoyez un message pour lui demander un rendez-vous !",
	"If you need any extra help, please contact our ": "Si vous avez besoin d'aide supplémentaire, veuillez contacter notre ",
	"support": "support",
	". We will be happy to help you.": ". Nous serons heureux de vous aider.",
	"Try your luck": "Tente ta chance",
	"64% of our users have a date in the first 2 weeks.": "64% de nos utilisateurs ont un rendez-vous dans les 2 premières semaines.",
	"Good Luck!.": "Bonne chance!",
	"*This message will be deleted upon closing.": "*Ce message sera supprimé à la fermeture.",

	"Private picture": "Photo privée",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Les photos privées apparaîtront floues. Seuls les utilisateurs qui ont déjà effectué un paiement peuvent les voir.",
	"Name change": "Changer le nom",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Si vous voulez changer votre nom ou pseudo, contactez notre support client et ils le feront le nécessaire dans les 24 heures.",
	"See all images": "Voir toutes les photos",
	"Unlock all private photos forever for just 20 credits": "Débloquer les photos privées pour toujours pour seulement 20 crédits",
	"Unlock forever all private pictures": "Débloquer les photos privées pour toujours",

	"This email address is not registered, please sign up to log in.": "Cet email n'est pas enregistré. Merci de vous inscrire pour pouvoir vous connecter.",
	"Congratulations, your account is now active.": "Félicitation, votre compte a été activé.",
	"Complete one last step and have fun!": "Complètez la dernière étape pour pouvoir en profiter !",
	"OR": "OU",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Nous vous avons envoyé un email avec le lien pour activer votre compte. Celui-ci sera valide pendant 12 heures :",
	"If you haven\'t received it in your inbox or ": "Si vous ne l'avez pas reçu dans votre boîte de reception ou dans ",
	"or you want to modify the email address, click on the button below.": "ou si vous souhaitez modifier l'adresse email, cliquez sur le bouton ci-dessous.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Nous vous avons envoyé un email pour valider votre compte qui est valable pour les 12 prochaines heures:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Si vous ne trouvez pas de courriel dans votre boîte de messagerie ou Spam, vous pouvez le transférer à nouveau ou le modifier",
	"Resend or change email": "Renvoyer ou modifier un email",
	"Please use the same email you registered.": "Sélectionnez le même e-mail d'enregistrement.",
	"Connect with Google": "Connexion avec Google",
	"Connect with Yahoo": "Inscrit toi avec Yahoo",
	"Connect with Microsoft": "Connexion avec Hotmail",
	"Or turn back": "Revenir en arrière",
	"Complete your registration": "Complète ton inscription",

	"It will not be shared": "Ne sera pas partagé",
	"It will never be shared. 100% Privacy": "Ne seras jamais partagé. 100% privé",

	"Unlock all private pictures forever.": "Débloquer les photos privées pour toujours.",
	"Unlock": "Débloquer",

	"You will be redirected in a second": "Vous serez redirigé dans quelques secondes",

	"Card payment": "Paiement par carte",
	"Bank transfer": "Virement Bancaire",
	"Prepaid card": " Carte prépayée",
	"SofortBanking": "Virement Bancaire",

	"Error": "Erreur",
	"page not found": "Page non trouvée",
	"Back to homepage": "Revenir à la page d'accueil",

	"per month": "par mois",
	"week": "semaine",
	"{count} week": "{count} semaine",

	"Your profile has been blocked": "Votre profil a été bloqué",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "Afin d’offrir une meilleure expérience à nos utilisateurs, nous bloquons les profils qui n’ont pas envoyé de message 21 jours après leur création.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "Pour ne pas perdre votre profil et pouvoir utiliser toutes les fonctionnalités à nouveau, vous devez acheter un pack de crédits.",
	"Restore access to:": "Accédez à nouveau à :",
	"All messages": "Tous les messages",
	"Users search": "Rechercher des utilisateurs",
	"Your contacts": "Vos contacts",
	"All favourites": "Tous les favoris",
	"All pictures": "Toutes les photos",
	"Send Icebreakers": "Envoyer un brise-glace",
	"Unblock Profile": "Déverrouiller le profil",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Ce site Web utilise ses propres cookies et identifiants pour améliorer l'accessibilité et analyser notre trafic. Si vous continuez à naviguer, nous considérons que vous acceptez leur utilisation. Pour plus d'informations, visitez notre",
	"Cookies Policy": "politique de cookies",

	"DATE GUARANTEE": "RENDEZ-VOUS GARANTI",
	"Take advantage of our Date guarantee": "Sur notre site, nous vous offrons une réelle garantie de rendez-vous",
	"No date? Money Back!": "Pas de rendez-vous ? Remboursement",
	"No risk": "Sans risque",
	"Guarantee of success": "Garantie de rencontres physiques",
	"Terms and Conditions": "Termes et conditions générales",

	"Boost your visibility": "Augmentez votre visibilité",
	"SAVE": "ÉCONOMISE",
	"Add 3 months premium": "Ajoutez 3 mois premium",
	"Add {months} months premium": "Ajoutez {months} mois premium",

	"We guarantee you a real date.": "Sur notre site, nous vous offrons une réelle garantie de rendez-vous.	",
	"YES, I WANT A DATE": "OUI, JE VEUX UN RENDEZ-VOUS",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Conditions générales de la garantie de rendez-vous. Pour remplir toutes les conditions de remboursement, vous devez avoir un profil complet et une description pour une période d'au moins six mois. Votre profil doit contenir au moins une photo de profil approuvée pendant cette période. De plus, chaque semaine, vous devez communiquer avec au moins sept membres différents situés près de chez vous (rayon de 100 km) dans votre tranche d'âge (-5 / + 5 ans). Si vous ne emplissez toutes les conditions énumérées ci-dessus et que vous ne trouvez pas de rendez-vous, nous vous rembourserons intégralement tous les paiements effectués à partir de votre inscription. Cette garantie n'est valable que 12 mois après votre inscription. Bonne chance !",

	"You have {count} days premium left": "Il vous reste {count} jours premium",
	"Check our special offer before deleting your account!": "Profitez de notre offre spéciale avant de supprimer votre compte !",
	"Delete my account": "Supprimer mon compte",
	"Free": "Gratuit",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Les packs Premium, Elite et VIP se renouvellent automatiquement, c'est-à-dire qu'ils se renouvelleront automatiquement à l'expiration de leur durée.",
	"and can confirm that I am 18 years or older.": "et je confirme que j’ai plus de 18 ans.",

	"COMPLETE YOUR PURCHASE": "FINALISE TON ACHAT",
	"Payment details": "Dètails du paiement",
	"Total": "Total",
	"3-digit number on the back of your credit card": "Numéro à 3 chiffres au dos de votre carte de crédit",
	"MM / YY": "MM / JJ",
	"MM": "MM",
	"YY": "JJ",
	"Name on card": "Nom sur carte",
	"Charges will appear discreetly as tpmpsup.com": "Des frais figureront discrètement comme tpmpsup.com",
	"Buy Now": "Acheter Maintenant",
	"Premium package 3 Months": "Pack Premium 3 mois",

	"This purchase was declined. Please check your details and try again.": "Votre achat a échoué. Meric de vérifier vos données et réessayez.",
	"Your purchase was succesful.": "Votre achat a été réalisé avec succès.",

	"SPECIAL OFFER": "OFFRE SPÉCIALE",
	"Unlock your account with our special offer": "Débloque ton compte avec ton offre spéciale",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Profite de notre offre d'achat pour débloquer ton profil et restaurer toutes tes fonctionnalités.",

	"card_number_require": "Numéro de carte requis",
	"card_number_invalid": "Numéro de carte invalide",
	"card_name_require": "Nom du titulaire de la carte requis",
	"card_name_invalid": "Nom du titulaire de la carte invalide",
	"card_cvc_require": "CVV requis",
	"card_cvc_invalid": "CVV invalide",
	"card_expiry_require": "Date d'expiration de la carte requise",
	"card_expiry_invalid": "Date d'expiration de la carte invalide",

	"Private photo": "Photo privée",
	"Search for someone now": "Rechercher des personnes maintenant",
	"Edit": "Rédiger",

	"Locked Album": "Album verrouillé",
	"Get credits to unlock all albums": "Obtient des crédits et ouvre tous les albums",
	"picture ::: pictures": "photo ::: photos",
	"Open": "Ouvrir",

	"Receive notifications": "Recevoir des notifications",
	"I want to be notified when I receive a message": "Je veux recevoir une notification quand je reçois un message",
	"No": "Non",
	"Yes": "Oui",

	"logout_confirm_title": "Se déconnecter", 
	"Are you sure you want to log out?": "Êtes-vous sur de vouloir vous déconnecter ?",

	"Your payment has been cancelled": "Votre paiement a été annulé",
	"Something went wrong, please try again in 10 minutes": "Une erreur est survenue, merci de retenter dans 10 minutes",

	"Please accept the terms and conditions to continue": "S'il te plaît, accepte les termes et conditions pour continuer",
	"By checking this box and clicking continue I accept the": "En cochant cette case et en cliquant sur continuer j'accepte les",
	"rg_terms": "Conditions Générales d’Utilisation",
	"rg_privacy": "la Politique de Confidentialité",
	"rg_cookies": "l’usage des Cookies",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "j'ai connaissance de l'utilisation de profils fictifs sur le site et je confirme être majeur.",

	"Cookie": "Cookie",
	"Responsible Party": "Partie responsable",
	"Category": "Catégorie",
	"Cookie Name": "Nom",
	"Purpose": "Utilisation",
	"Lifespan": "Durée de vie",
	"Type of Cookie": "Type de Cookie",
	"Strictly necessary": "Strictement nécessaires",
	"Functional": "Fonctionnelles",
	"Performance": "Performance",
	"Advertising": "Advertising",
	"cookie-policy": "politique-des-cookies",
	"cookie policy": "politique des cookies",

	"We Care About Your Privacy": "Nous nous soucions de ta vie privée",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Ce site utilise ses propres cookies, techniques et tiers pour améliorer les performances de navigation, analyser le comportement des visiteurs et nous aider, ainsi que nos partenaires marketing, à mesurer les résultats des campagnes et publicité personnalisée. En cliquant sur \"Tout accepter\", tu acceptes tous ces cookies.",
	"Accept All": "Tout accepter",
	"Cookies Settings": "Paramètres des cookies",
	"About your privacy": "À propos de ta vie privée",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "Nous utilisons des cookies pour l'équilibrage du trafic, l'identification de session, l'analyse et l'attribution marketing. Tu peux choisir de participer ou de ne pas participer à tout ou une partie d'entre eux à partir de ce panneau.",
	"You will be able to change your set up at any time from our :cookies_policy page": "Tu peux modifier tes paramètres à tout moment depuis notre page de :cookies_policy",
	"Accept all": "Tout accepter",
	"Manage Consent Preferences": "Gérer les préférences de consentement",
	"Technical (Mandatory)": "Techniques (strictement nécessaires)",
	"Performance (Monitor website performance)": "Performance (performances du site Web)",
	"Functional (User experience improvement)": "Fonctionnelles (améliorer l'expérience utilisateur)",
	"Advertising (Marketing measurement)": "Piblicité (mesures de marketing)",
    "Advertising (Personalization)": "Publicité (personnalisation)",
	"Save Selection": "Enregistrer la sélection",
	"Reject All": "Tout refuser",

	"We and our partners process data to provide:": "Nous et nos partenaires traitons les données à fournir:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Utiliser des données de géolocalisation précises. Analyser activement les caractéristiques de l'appareil pour l'identification. Stocker et/ou accéder à des informations sur un appareil. Contenu et publicités personnalisés, mesure des publicités et du contenu, informations sur l'audience et développement de produits.",
	"List of partners": "Liste des partenaires",
	"Cookie Policy": "Politique de cookies",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Ces cookies sont utilisés dans le cadre du suivi des actions liées aux publicités",
	"Delete cookies": "Cookies",
	"Save selection": "Enregistrer la sélection",
	"Reject all": "Tout refuser",

	"{name} hasn't replied to you yet. Send another message!": "{name} ne t'a pas encore répondu. Envoie-lui un autre message!",
	"Validate my Ice-breaker": "Valider mon brise-glace",
	"now": "maintenant",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "À partir du 1er juin 2023, il ne sera plus possible d’effectuer des paiements avec PaySafeCard.",

	"Dear User,": "Cher utilisateur,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Merci de votre intérêt à rejoindre notre site Web, mais, afin de maintenir un équilibre de 50% entre les hommes et les femmes, l'inscription pour les hommes n'est pas autorisée pour le moment.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Si vous voulez toujours rencontrer quelqu'un, cliquez sur le bouton et essayez un des meilleurs sites Web en ligne.",
	"Try Now": "Essayez maintenant",
	
	"Your account has been deleted.": "Votre compte a été supprimé.",
	"According to your choices, our algorithms have found a better option for you.": "En fonction de vos choix, notre algorithme a trouvé une bien meilleure option pour vous.",
	"See More": "Voir plus",

	"10 credits for you!": "10 crédits pour vous !",
	"Get 3 free Icebreakers": "Obtenez 3 brise-glace gratuits",
	"Free Icebreakers": "Brise-glace gratuits",
	"Congratulations, you have received 3 Icebreakers!": "Félicitations, vous avez gagné 3 brise-glace !",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay ne fonctionne qu’à partir du navigateur Safari. Veuillez ouvrir Safari et vous connecter à votre compte pour effectuer le paiement.",

	"No nudity": "Nudité interdite",
	"Nudity allowed": "Nudité autorisée",

	"Edit profile": "Editer le profil",
	"Online": "En ligne",
	"Step": "Prochaine étape",
	"Completed": "Compléter",
	"Help": "Aide",
	"or Drag and drop": "ou faire glisser et déposer",

	"You will be able to send messages in": "Vous pourrez envoyer des messages dans",
	"Send": "Envoyer",
	"Success": "Succès",
	"Start a conversation with {name}": "Entamer une conversation avec {name}",
	"Failed": "Échec",
	"Congratulations, your account has been successfully created.": "Félicitations, votre compte a été créé avec succès.",
	"Contact our support": "Contacter le support",
	
	"About": "A propos de",
	"Scale": "Ajuster",
	"Rotate": "Pivoter",

	"No favorites added": "Aucun favoris ajouté"
	
}
