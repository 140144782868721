export default {
	"Already a member?": "Schon Mitglied?",
	"Login": "Einloggen",
	"Log out": "Ausloggen",
	"Your current status": "Dein aktueller Status",
	"BASIC": "BASIC",
	"Basic": "Basic",
	"Bronze": "Bronze",
	"Elite": "Elite",
	"VIP": "VIP",
	"Join Us": "Jetzt anmelden",
	"Find people near you": "Finde Leute in deiner Nähe",
	"Join now": "Jetzt suchen",
	"I am a": "Ich bin ein",
	"Email": "E-Mail",
	"It will not be published": "Es wird nicht veröffentlicht",
	"E-mail address": "E-Mail Adresse",
	"SEARCH NOW": "JETZT SUCHEN",
	"I confirm that I am over 18 years old": "Ich bestätige, dass ich über 18 Jahre alt bin",
	"Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "Denken Sie nicht nur darüber nach! Entdecken Sie die heißeste Dating Seite in Deutschland. Jeden Tag kommen Tausende von Menschen wie Sie zu uns.",
	"Information": "Information",
	"All rights reserved.": "Alle Rechte vorbehalten",
	"SUBSCRIPTION": "ABONNEMENT",
	"Other": "ANDERE",
	"All fields are required": "alle Felder sind erforderlich",
	"The fields marked in Red are required. Please fill them to continue.": "Die rot markierten Felder sind Pflichtfelder. Bitte füllen Sie diese aus, um fortzufahren.",
	"If you did not find a suitable answer please contact us": "Wenn Sie keine passende Antwort gefunden haben, kontaktieren Sie uns bitte",
	"Will help us to identify you": "Wird uns helfen, Sie zu identifizieren",
	"Invalid Username": "Ungültiger Benutzername",
	"Subject": "Thema",
	"Tell us how can we help you": "Sagen Sie uns, wie wir Ihnen helfen können",
	"Please enter subject": "Bitte geben Sie einen Betreff ein",
	"Please enter at least 10 characters": "Bitte geben Sie mindestens 10 Zeichen ein",
	"Connected to your account": "Verbunden mit Ihrem Konto",
	"Repeat Email": "Wiederholen Sie die E-Mail-Adresse",
	"Emails did not match": "E-Mail Adressen stimmen nicht überein",
	"Message": "Nachricht",
	"No Recent Activity": "Keine aktuellen Aktivitäten",

	//route labels
	"Home": "Home",
	"Contact": "Kontakt",
	"Support": "Support",
	"Affiliates": "Affiliates",
	"Privacy": "Datenschutz",
	"Terms": "AGB",

	//routes uri
	"/login": "/einloggen",
	"/contact": "/kontakt",
	"/support": "/support",
	"/affiliates": "/affiliates",
	"/privacy": "/privatsphare",
	"/terms-and-conditions": "/geschaftsbedingungen",
	"/cookie-policy": "/cookies-richtlinie",
	"/subscription/exit": "/abo/ausstieg",
	"/purchase-confirmation": "/kaufbestatigung",
	"/page/:page_name": "/seite/:page_name",

	"/dashboard": "/dashboard",
	"/mobile-dashboard": "/mobiles-dashboard",
	"/activate-profile/:token": "/aktiviere-profil/:token",
	"/edit-profile": "/profil-bearbeiten",
	"/profile/:name": "/profil/:name",
	"/my-picture": "/mein-bild",
	"/messages/inbox": "/nachrichten/inbox",
	"/messages/trash": "/nachrichten/müll",
	"/messages/send/:name": "/nachrichten/senden/:name",
	"/visitor": "/besucher",
	"/favorite/my": "/favorit/mein",
	"/favorite/others": "/favorit/andere",
	"/search": "/suche",
	"/search-result": "/such-ergebnis",
	"/settings": "/einstellungen",
	"/validate-new-email-address/:token": "/neue-email-adresse-bestatigen/:token",
	"/subscription": "/abonnement",
	"/credits": "/credits",
	"/elite": "/elite",
	"/faq": "/faq",
	"/delete-account": "/konto-loschen",

	"/exclusive-promotion": "/exklusive-forderung",
	"/account-deleted": "/konto-geloscht",
	"/logged-out": "/abgemeldet",

	//login
	"Login": "Einloggen",
	"Enter username or Email": "Benutzername oder E-Mail eingeben",
	"Enter Password": "Passwort eingeben",
	"Username": "Benutzername",
	"Nickname": "Nickname",
	"Password": "Passwort",
	"I forgot my password": "Ich habe mein Passwort vergessen",
	"Password Reminder": "Passwort-Erinnerung",
	"Enter you email": "Geben Sie Ihre E-Mail-Adresse ein",
	"Your email address": "Ihre E-Mail Adresse",
	"Don't have an account?": "Sie haben noch keinen Account?",
	"REGISTER": "REGISTRIEREN",
	"Password sent to your email": "Passwort an Ihre E-Mail gesendet",
	"This account has not been validated yet": "Dieser Account wurde noch nicht bestätigt",
	"LOGIN USING YOUR CREDENTIALS": "LOGIN UNTER VERWENDUNG IHRER ANMELDEDATEN",
	"FORGOT YOUR PASSWORD?": "HABEN SIE IHR PASSWORT VERGESSEN?",
	"Login failed": "Anmeldung fehlgeschlagen",
	"Username or password is incorrect": "Benutzername oder Passwort ist falsch",

	"We're sorry!": "Es tut uns leid",
	//registration
	"You're almost done!": "Sie sind fast fertig!",
	"Please fill your additional account information": "Bitte geben Sie Ihre zusätzlichen Kontoinformationen ein",
	"Just one step left!": "Nur noch einen Schritt!",
	"Let us learn more about you. Fill in your personal data": "Lassen Sie uns mehr über Sie erfahren. Tragen Sie Ihre persönlichen Daten ein",
	"Activate your account now.": "Aktivieren Sie jetzt Ihr Konto.",
	"Activate your account now": "Aktivieren Sie jetzt Ihr Konto",
	"Please confirm your e-mail address": "Bitte bestätigen Sie Ihre E-Mail-Adresse",
	"Please fill in the following form to join {site_name} for free": "Bitte füllen Sie das folgende Formular aus, um sich kostenlos bei {site_name} anzumelden",
	"CONTINUE": "WEITER",

	"By clicking on the continue button I agree with the": "Durch Anklicken des Buttons \“Weiter\” stimme ich der",
	"terms": "AGBs",
	"and cookies": "und den Cookies zu",

	"This site is protected by reCaptcha and the Google": "Diese Website ist durch reCaptcha und Google geschützt",
	"Privacy Policy": "Datenschutz-Bestimmungen",
	"and": "und",
	"Terms of Service": "Nutzungsbedingungen",
	"apply.": "anwenden.",

	"Personal description": "Personenbeschreibung",
	"optional": "optional",
	"FINALIZE": "FERTIGSTELLEN",
	"We have just sent you a confirmation e-mail at": "Wir haben Ihnen soeben eine Bestätigungs-E-Mail an gesendet",
	"To start using our website, please click on the activation link in this email.": "Um unsere Website zu nutzen. klicken Sie bitte auf den Aktivierungslink in dieser E-Mail.",
	"Click here to verify your email": "Klicken Sie hier, um Ihre E-Mail-Adresse zu bestätigen",
	"CLICK TO ACTIVATE": "KLICKEN ZUM AKTIVIEREN",
	"CLICK TO ACTIVATE - desktop": "KLICKEN ZUM AKTIVIEREN",
	"VERIFY MY EMAIL": "E-MAIL BESTÄTIGEN",
	"Not received any email yet?": "Noch keine E-Mail erhalten?",
	"Please check your": "Bitte prüfen Sie ihren",
	"spam": "Spam",
	"box and wait at least 10 minutes or re-send activation": "Ordner und warten Sie mindestens 10 Minuten oder senden Sie die Aktivierung erneut",
	"Enter Nickname": "Nickname eingeben",
	"Enter Your Name": "Geben Sie ihren Namen ein",
	"Password Required": "Passwort erforderlich",
	"Enter valid Email": "Geben Sie eine gültige E-Mail-Adresse ein",
	"Please enter at least 5 characters": "Bitte geben Sie mindestens 5 Zeichen ein",
	"Enter strong password": "Geben Sie ein sicheres Passwort ein",
	"Email not available": "E-Mail nicht verfügbar",
	"Nickname not available": "Nickname nicht verfügbar",
	"Maximum 4 number allowed": "Maximal 4 Nummern erlaubt",
	"Name should not contain any space, number or special character": "Der Name darf kein Leerzeichen enthalten, Nummer oder Sonderzeichen",
	"Nickname should not contain any space or special characters": "Der Nickname darf keine Leerzeichen oder Sonderzeichen enthalten",
	"Your activation mail has been sent": "Ihre Aktivierungsmail wurde gesendet",
	"Complete your profile in a minute and start contacting people": "Vervollständigen Sie Ihr Profil in einer Minute und nehmen Sie Kontakt mit anderen auf",
	"Complete your profile": "Profil vervollständigen",
	"Create Profile": "Profil erstellen",
	"Your description": "Ihre Beschreibung",
	"Confirm your Email": "Bestätigen Sie ihre E-Mail Adresse",
	"We have sent you an email.": "Wir haben Ihnen eine E-Mail geschickt.",
	"Check your email to complete your registration": "Überprüfen Sie Ihre E-Mails, um Ihre Registrierung abzuschließen",
	"and be able to speak with other users.": "und seien Sie in der Lage, mit anderen Benutzern zu sprechen.",
	"If you can not find it look it up in the spam or": "Wenn Sie es nicht finden können, suchen Sie es im Spam oder",
	"If you have not received it select one of the following options": "Wenn Sie es nicht erhalten haben, wählen Sie eine der folgenden Optionen",
	"junk folder": "Papierkorb",
	"Change Email": "Ändern Sie die E-Mail Adresse",
	"Resend Email": "E-Mail zurücksenden",
	"Resend": "Zurücksenden",
	"New Email": "Neue E-Mail Adresse",
	"Your activation key is invalid": "Ihr Aktivierungsschlüssel ist ungültig",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Bitte geben Sie ein '@' in die E-Mail-Adresse ein. '{email}' hat kein '@'",

	//public pages
	"privacy": "privatsphäre",
	"terms-and-conditions": "geschäftsbedingungen",
	"about-us": "über-uns",

	//forms
	"Name": "Name",
	"Marital Status": "Familienstand",
	"Date of Birth": "Geburtsdatum",
	"Country": "Land",
	"Location": "Ort",
	"Province": "Bundesland",
	"City": "Stadt",
	"Save Changes": "Änderungen speichern",
	"Select from list": "Aus Liste auswählen",
	"Day": "Tag",
	"Month": "Monat",
	"month": "monat",
	"Year": "Jahr",
	"Prefer not to say": "Mache lieber keine Angabe",
	"Only profiles with photo": "Nur Profile mit Foto",
	"This field is required": "Dieses Feld wird benötigt",
	"Please update your profile": "Bitte aktualisieren Sie Ihr Profil",
	"Profile successfully updated": "Profil erfolgreich aktualisiert",
	"The fields marked in red are required. Please fill them to continue.": "Die rot markierten Felder sind Pflichtfelder. Bitte füllen Sie diese aus, um fortzufahren.",
	"Characters left": "Zeichen übrig",
	"Image attached": "Bild angehängt",
	"The message field is required.": "Das Nachrichtenfeld ist erforderlich.",
	"WITH PICTURE": "MIT FOTO",
	"all age groups": "Alter",
	"EDIT DESCRIPTION": "BESCHREIBUNG BEARBEITEN",
	"DESCRIPTION": "BESCHREIBUNG",
	"FEATURING": "EIGENSCHAFTEN",
	"Please Select": "Bitte auswählen",
	"Emails do not match": "E-mail Adressen stimmen nicht überein",
	"Your request was succesfully sent!": "Ihre Anfrage wurde erfolgreich versendet!",
	"Or send us a message directly": "Oder senden Sie uns direkt eine Nachricht",

	//user pages
	"My Profile": "Mein Profil",
	"Add Photos": "Fotos hinzufügen",
	"Messages": "Nachrichten",
	"Favorites": "Favoriten",
	"Search": "Suche",
	"Settings": "Einstellungen",
	"Visits": "Besuche",
	"Likes": "Likes",
	"Membership": "Mitgliedschaft",
	"Vip Members": "VIP Mitglieder",

	"Account activation successful": "Kontoaktivierung erfolgreich",
	"My chats": "Meine Chats",

	//user home
	"Quick Search": "Schnelle Suche",
	"More Options": "Mehr Optionen",
	"I am a": "Ich bin ein",
	"Looking for": "Auf der Suche nach",
	"Age": "Alter",
	"Search for": "Suchen nach",
	"All age groups": "Alter",
	"Popular Members": "Beliebte Mitglieder",
	"New Members": "Neue Mitglieder",
	"Find a Match": "Einen Treffer finden",
	"Recent Activity": "Letzte Aktivität",
	"Add Photo": "Foto hinzufügen",
	"Back": "zurück",
	"Become Elite": "Elite",
	"Upgrade": "Verbessern",

	"likes": "likes",
	"EDIT MY PROFILE": "MEIN PROFIL BEARBEITEN",
	"CONFIGURATION": "KONFIGURATION",
	"ONLINE SUPPORT": "ONLINE SUPPORT",

	//user message
	"Trash": "Müll",
	"Delete Selected": "Ausgewählte löschen",
	"No Messages Available": "Keine Nachrichten verfügbar",
	"No Messages selected": "Keine Nachrichten ausgewählt",
	"Back to inbox": "Zurück zum Posteingang",
	"Back to messages": "Zurück zu den Nachrichten",
	"Select all": "alle auswählen",
	"Deselect all": "alle löschen",
	"Next Page": "nächste Seite",
	"Prev Page": "vorherige Seite",
	"Type your message here": "Schreiben Sie Ihre Nachricht hier",
	"Send message": "Nachricht senden",
	"Send Icebreaker": "Icebreaker senden",
	"VIEW PROFILE": "PROFIL ANZEIGEN",
	"DELETE": "LÖSCHEN",
	"Chat with": "Chatten mit",
	"Chat": "Chatten",

	//user favorites
	"My Favorites": "Meine Favoriten",
	"No likes": "Keine Likes",
	"Favorite added successfully": "Favorit erfolgreich hinzugefügt",
	"Favorite deleted successfully": "Favorit erfolgreich gelöscht",
	"Visit Profile": "Profil besuchen",

	//user visits
	"My Profile Visitors": "Mein Profil Besucher",
	"No Visitors": "Keine Besucher",

	//user photos
	"My pictures": "Meine Fotos",
	"See my public profile": "Mein öffentliches Profil anschauen",
	"Profile photo": "Profilfoto",
	"Profile Picture": "Profilbild",
	"2MB or lower.": "2 MB oder weniger.",
	"2MB or lower": "2 MB oder weniger.",
	"Nudes not allowed in this category.": "Nacktfotos sind in dieser Kategorie nicht erlaubt.",
	"PHOTO ALBUM": "FOTOALBUM",
	"Allowed images 2 megabytes or less": "Zulässige Bilder 2 MB oder weniger",
	"Add picture": "Bild hinzufügen",
	"Main reasons for rejection": "Hauptgründe für die Ablehnung",
	"Are you sure?": "Sind Sie sicher?",
	"Are you sure you want to delete this image?": "Möchten Sie dieses Bild wirklich löschen?",
	"Close": "Schließen",
	"Confirm": "bestätigen",
	"Attaching image": "Bild anhängen",
	"The message must be at least 2 characters.": "Die Nachricht muss aus mindestens 2 Zeichen bestehen.",
	"Deleting...": "Löschen ...",
	"Pictures of underage people": "Bilder von Minderjährigen",
	"Personal data is visible": "Persönliche Daten sind sichtbar",
	"Fake or stolen pictures": "Gefälschte oder gestohlene Bilder",
	"Different gender to profile": "Unterschied-liches Geschlecht zum Profil",
	"Group pictures": "Gruppenbilder",
	"Weapons, drugs, violence": "Waffen, Drogen, Gewalt",
	"No people visible": "Keine Personen sichtbar",
	"Info": "Info",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Wir überprüfen Ihre Bilder. Wenn sie den Überprüfungsprozess bestehen, werden sie den anderen Benutzern angezeigt.",

	//user profiles
	"Profile": "Profil",
	"yrs": "Jahre",
	"years": "Jahre",
	"yr from": "Jahre,",
	"Physique": "Körperbau",
	"Photos": "Fotos",
	"No Photos": "keine Fotos",
	"About Me": "Über mich",
	"Characteristics": "Eigenschaften",
	"Prefers": "Bevorzugt",
	"Relationship": "Beziehung",
	"Shape": "Körperform",

	//user edit profile
	"DESCRIBE YOURSELF": "Beschreiben Sie sich",
	"GENERAL": "ALLGEMEINES",
	"(Will be verified)": "(wird überprüft werden)",
	"Sex": "Sex",
	"Seeking": "Suche",
	"Finish your profile": "Vervollständigen Sie ihr Profil",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Herzlich willkommen! Da ist Ihr neues Profil, wir werden maximale Sichtbarkeit geben, sobald Sie es abgeschlossen haben.",

	//search profile
	"Advanced Search": "Erweiterte Suche",
	"OPEN TO": "OFFEN FÜR",
	"PREFERENCES": "VORLIEBEN",
	"CHARACTER": "CHARAKTER",
	"Search Result": "Suchergebnis",
	"No Member found": "Kein Mitglied gefunden",
	"Change Search": "Suche ändern",
	"Hair Color": "Haarfarbe",
	"Eye Color": "Augenfarbe",
	"Body Type": "Körperbau",
	"Height": "Größe",
	"Weight": "Gewicht",
	"Smoker": "Raucher",
	"Ethnicity": "Ethnien",
	"From": "von",
	"To": "bis",

	//settings
	"Change E-mail address": "E-Mail Adresse ändern",
	"Your email address has been successfully updated": "Ihre E-Mail-Adresse wurde erfolgreich aktualisiert",
	"Password": "Passwort",
	"Account Status": "Kontostatus",
	"Cancel": "Löschen",
	"Delete Account": "Konto löschen",
	"Notifications": "Benachrichtigungen",
	"Receive all notifications individually": "Erhalten Sie alle Benachrichtigungen einzeln",
	"Receive one daily email will all my notifications": "Erhalten Sie eine tägliche E-Mail mit all Ihren Benachrichtigungen",
	"Receive a weekly email with all my notifications": "Erhalten Sie eine wöchentliche E-Mail mit all Ihren Benachrichtigungen",
	"I don\'t want to receive any notifications": "Ich möchte keine Benachrichtigungen erhalten",
	"(we will send you a verification email)": "(Wir senden Ihnen eine Bestätigungs-E-Mail)",
	"Current Password": "aktuelles Passwort",
	"New Password": "neues Passwort",
	"Enter valid email": "Geben Sie eine gültige E-Mail-Adresse ein",
	"Repeat New Password": "neues Passwort wiederholen",
	"Save Settings": "Einstellungen speichern",
	"Passwords should match": "Passwörter sollten übereinstimmen",
	"Password length should be minimum 5 characters": "Das Passwort sollte mindestens 5 Zeichen lang sein",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "Achtung: Ihre neue E-Mail-Adresse muss überprüft werden, indem Sie auf den Link klicken, den wir Ihnen soeben gesendet haben.",
	"I want to receive notifications by email": "Ich möchte Benachrichtigungen per E-Mail erhalten",
	"Status": "Status",
	"Renewal": "Verlängerung",
	"Auto renewal Canceled": "Automatische Verlängerung abgebrochen",
	"Delete profile": "Profil löschen",
	"Your account has been deleted. You will be redirected.": "Ihr Account wurde gelöscht. Sie werden weitergeleitet.",
	"Successfully updated": "Erfolgreich aktualisiert",
	"Current account status": "Aktueller Kontostatus",
	"Are you sure you want to cancel your subscription?": "Möchten Sie Ihr Abonnement wirklich kündigen?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Ihr Abonnement wurde erfolgreich gekündigt und wird nicht automatisch verlängert",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "Aus technischen Gründen, die Aufhebung der Verlängerung muss nach 8 Stunden nach dem Erwerb erfolgen. Für jedes andere Abonnement, die Stornierung muss mindestens 8 Stunden nach dem Erwerb und vor Ablauf von 48 Stunden erfolgen.",
	"Profile deleted. Redirecting...": "Profil gelöscht. Weiterleiten ...",
	"*Active subscriptions deleted this way will not be reimbursed": "* Auf diese Weise gelöschte aktive Abonnements werden nicht erstattet",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Möchten Sie wirklich * Ihr Konto löschen? Ihre Daten werden dauerhaft gelöscht.",
	"Settings successfully updated": "Einstellungen erfolgreich aktualisiert",
	"Disable Notification": "Benachrichtigungen deaktivieren",
	"The notifications have been successfully disabled.": "Die Benachrichtigungen wurden erfolgreich deaktiviert.",
	"Canceled Auto-Renewal": "Automatische Verlängerung abgebrochen",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Nur VIP-Mitglieder können mehr als 5 verschiedene Benutzer pro Tag kontaktieren.",
	"Upgrade your status": "Verbessere deinen Status",
	"Enjoy our exclusive benefits and make new contacts": "Profitieren Sie von unseren exklusiven Vorteilen und knüpfen Sie neue Kontakte",
	"Users Online": "Benutzer online",
	"Discreet Billing. {site_name} will not appear in the invoice": "Diskrete Abrechnung. {site_name} wird nicht in der Rechnung angezeigt",
	"Month ::: Months": "Monat ::: Monate",
	"3 days trial": "3 Tage Probezeit",
	"Try 3 days": "Versuchen Sie es 3 Tage",
	"MEMBERSHIP": "MITGLIEDSCHAFT",
	"Select": "Auswählen",
	"Send messages for 3 consecutive days": "Senden Sie 3 aufeinanderfolgende Tage lang Nachrichten",
	"Only one trial subscription per user": "Nur ein Probeabonnement pro Benutzer",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "Um einen besseren Service ohne Unterbrechungen zu bieten, werden alle Pläne automatisch zu den Kosten eines der folgenden Abonnements erneuert, basierend auf dem oben ausgewählten Paket.",
	"for": "für",
	"For full access to all details of events, including how to cancel it,": "Für vollen Zugriff auf alle Details von Veranstaltungen, einschließlich, wie man es abbricht,",
	"click here": "Klicke hier",
	"for terms and conditions,": "für allgemeine Geschäftsbedingungen,",
	"All profiles are reviewed and moderated by our team.": "Alle Profile werden von unserem Team überprüft und moderiert.",
	"CURRENT PLAN": "AKTUELLER PLAN",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Basic (kein Probebonus) Sie haben derzeit kein aktives Paket und sind ein BASIC-Benutzer. Um Ihre Chancen, mit anderen Benutzern in Kontakt zu treten zu erhöhen, müssen Sie auf ein Elite- oder VIP-Status upgraden.",
	"MY BENEFITS": "MEINE VORTEILE",
	"{discount} cheaper": "{discount} günstiger",
	"save": "speichern",
	"Create my profile": "Mein Profil erstellen",
	"Read messages": "Nachrichten lesen",
	"Add to Favorites": "Zu den Favoriten hinzufügen",
	"Make yourself visible to other users by adding them to favorites": "Machen Sie sich für andere Benutzer sichtbar, indem Sie sie zu Favoriten hinzufügen",
	"Photo Album": "Fotoalbum",
	"No fake pictures allowed": "Keine gefälschten Bilder erlaubt",
	"Album pictures": "Fotoalbum",
	"Show yourself and increase your possibilities": "Zeigen Sie sich und erweitern Sie Ihre Möglichkeiten",
	"Detailed profile search": "Detaillierte Profilsuche",
	"Find exactly what you are looking for": "Finden Sie genau das, wonach Sie suchen",
	"See other user's pics": "Sehen Sie die Bilder anderer Benutzer",
	"Choose your favorite": "Wählen Sie ihren Favoriten",
	"Reply to and send messages": "Antworten Sie auf und senden Sie Nachrichten",
	"Make direct contact with up to five users per day": "Nehmen Sie täglich direkten Kontakt mit bis zu fünf Benutzern auf",
	"Be shown first in searches": "Bei Suchanfragen zuerst angezeigt werden",
	"Your profile will be featured before the rest of the users": "Ihr Profil wird vor den anderen Nutzern angezeigt",
	"Make direct contact with unlimited users": "Stellen Sie direkten Kontakt mit unbegrenzten Benutzern her",
	"Choose payment method": "Zahlungsart auswählen",
	"LESS THAN": "WENIGER ALS",
	"PER DAY": "PRO TAG",
	"DISCRETION": "DISKRETION",
	"Our website name will not be disclosed": "Der Name unserer Website wird nicht bekannt gegeben",
	"Our name will not be disclosed": "Unsere Webseite wird nicht bekannt gegeben",
	"100% Anonymous.": "100% Anonym.",
	"Choose your subscription": "Wählen Sie Ihr Abonnement",
	"Recommended": "Empfohlen",
	"Most Popular": "Am beliebtesten",
	"Secure payments with": "Sicher bezahlen mit",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Ihre Nachricht wurde NICHT gesendet. Sie müssen Ihren Status aktualisieren, um Nachrichten senden zu können.",
	"Your purchase has been successful": "Ihr Einkauf war erfolgreich",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Alle Abonnements verlängern sich automatisch, um einen optimalen Service zu gewährleisten. Wenn Sie die Einstellungen ändern möchten, können Sie dies tun",
	"here.": "hier.",
	"after_here": "",

	//ad
	"Become a": "Werden Sie ein",
	"PREMIUM MEMBER": "PREMIUM MITGLIED",
	"And contact": "Und kontaktieren Sie",
	"more people": "mehr Leute",

	"upgrade": "verbessern",
	"Boost": "Verbessern",
	"boost": "Verbessern",
	"your profile": "Sie Ihr Profil",
	"Start chatting and meet more people": "Fangen Sie an zu chatten und treffen Sie mehr Leute",
	"And get": "Und erhalten",
	"more views": "Sie mehr Besuche",

	"Now +3 extra Credits with each purchase": "Jetzt +3 extra Credits bei jedem Einkauf",
	"Best Seller": "Bestseller",
	"Select your Package": "Wählen Sie Ihr Paket",
	"Less than {amount} per credit": "Weniger als {amount} pro Credit",
	"Less than": "Weniger als",
	"per credit": "pro Credit",
	"Free credits": "Kostenlose Credits",
	"per purchase": "pro Kauf",
	"Premium Status doesn’t allow free messages.": "Der Premium-Status erlaubt keine kostenlosen Nachrichten.",
	"Chat now": "Chatten sie jetzt",
	"Get Credits": "Credits bekommen",
	"Credit": "Credit",
	"Credits": "Credits",
	"My credits": "Meine Credits",
	"{count} Credits left": "{count} Credits übrig",
	"Your message has not been sent, you need credits to be able to send messages.": "Ihre Nachricht wurde nicht gesendet, Sie benötigen Credits, um Nachrichten senden zu können.",
	"Icebreaker": "Icebreaker",
	"Icebreaker today": "heutige Icebreaker",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Die Kosten für den Versand eines Icebreakers betragen 2 Credits. Der Icebreaker kann nur zum Starten eines Gesprächs oder als Reaktion auf einen anderen Icebreaker verwendet werden.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Mit dem Erwerb jedes Pakets, erwerben Benutzer eine Packung Credits, die zum Senden von Nachrichten verwendet werden können. Das Verhältnis der Credits pro Nachricht beträgt zehn zu eins, das heißt, der Benutzer muss mindestens zehn Credits in seinem Konto haben, um eine Nachricht zu senden. Die Kosten für den Versand eines Icebreakers betragen 2 Credits.",

	"Trial pack": "Testpaket",
	"Only one trial pack per user allowed": "Pro Benutzer ist nur ein Testpaket zulässig",
	"Used": "Benutzt",

	"Exclusive Promotion": "Exklusive Werbung",
	"Account Deleted": "Konto gelöscht",
	"Log out Successful": "Abmelden erfolgreich",

	"Do you like FREE LIVE SEX?": "Gefällt dir KOSTENLOSER LIVE SEX?",
	"Enter this code to enjoy a 10% discount:": "Mit diesem Code erhalten Sie 10% Rabatt:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Dieser Code ist für Ihren ersten Online-Einkauf bestimmt, und ist nur für die nächsten 6 Stunden gültig.",

	"This account has been deleted.": "Dieses Konto wurde gelöscht.",
	"Our algorithm has found a better option in line with your taste.": "Unser Algorithmus hat eine bessere Option nach Ihrem Geschmack gefunden.",
	"You will be redirected in a few seconds.": "Sie werden in wenigen Sekunden weitergeleitet.",

	"Has added you to favorites": "Hat Sie zu Favoriten hinzugefügt",
	"Is looking at your profile": "Schaut sich Ihr Profil an",
	"See profile": "Siehe Profil",
	"See message": "Siehe Nachricht",

	"I think I am in love": "Ich glaube ich bin verliebt",
	"Hey! I'm winking at you": "Hallo! Ich zwinkere dir zu",
	"Tell me more about you. Fancy a chat?": "Erzähl mir mehr über dich. Lust auf einen Chat?",
	"Some flowers for you!": "Ein paar Blumen für dich!",
	"Sending you a kiss": "Ich schicke dir einen Kuss",
	"Surprise, it\'s me!": "Überraschung, Ich bin's!",
	"Hi, how are you?": "Hi, Wie geht's dir?",
	"Let's get naughty": "Lass uns unanständig werden",

	"Get 5 times the regular response rate. Premium members benefits:": "Erhalten Sie die 5-fache reguläre Rücklaufquote. Vorteile für Premium-Mitglieder:",
	"Appear high up on searches": "Erscheinen Sie bei Suchanfragen ganz oben",
	"Distinctive badge that increases your visibility": "Markantes symbol, das Ihre Sichtbarkeit erhöht",
	"5 Free Icebreakers a day": "5 kostenlose Icebreaker pro Tag",
	"In addition to this, a voucher for a 15% Webcam discount": "Außerdem, einen Gutschein über 15% Webcam-Rabatt",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Der Icebreaker kann nur zum Starten eines Gesprächs oder als Reaktion auf einen anderen Icebreaker verwendet werden.",

	"Succesful purchase": "Erfolgreicher Kauf",
	"Your purchase has been successful.": "Ihr Einkauf war erfolgreich.",
	"It can take a few minutes to update.": "Das Update kann einige Minuten dauern.",
	"You will be redirected.": "Sie werden weitergeleitet.",

	"Changes can take some minutes to update.": "Das Aktualisieren von Änderungen kann einige Minuten dauern.",

	"Yearly Package": "Jährliches Paket",
	"Package": "Paket",
	"Buy Now": "Kaaufen Sie jetzt",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "Mit dem Elite-Status können Sie täglich bis zu 5 verschiedene Benutzer kontaktieren, indem Sie ihnen unbegrenzt Nachrichten senden.",

	"Exclusive offer": "Exclusives Angebot",
	"Welcome": "Willkommen",
	"Mary from {domain_name} support": "Mary vom {domain_name} Support",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Willkommen bei {domain_name}. Wir hoffen, Sie finden, wonach Sie suchen.",
	"Here are some Pro Tips to be more successful and meet people:": "Hier sind einige Pro-Tipps, um erfolgreicher zu sein und Leute zu treffen:",
	"Upload a profile picture to get more connections.": "Laden Sie ein Profilbild hoch, um weitere Verbindungen zu erhalten.",
	"Send an icebreaker to get a conversation easily started.": "Senden Sie einen Icebreaker, um ein Gespräch zu beginnen.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Wenn Sie jemanden mögen, vergessen Sie nicht, ihn zu Ihren Favoriten hinzuzufügen, damit er es weiß.",
	"And of course, if you like somebody send a message and ask for a date!": "Und natürlich, wenn Sie jemanden mögen, senden Sie eine Nachricht und fragen Sie nach einem Date!	",
	"If you need any extra help, please contact our ": "Wenn Sie zusätzliche Hilfe benötigen, kontaktieren Sie bitte unseren ",
	"support": "Support",
	". We will be happy to help you.": ". Wir helfen Ihnen gerne weiter.",
	"Try your luck": "Versuchen Sie Ihr Glück",
	"64% of our users have a date in the first 2 weeks.": "64% unserer Nutzer haben in den ersten 2 Wochen ein Date.",
	"Good Luck!.": "Viel Glück!.",
	"*This message will be deleted upon closing.": "* Diese Nachricht wird beim Schließen gelöscht.",

	"Private picture": "Privates Bild",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Private Bilder werden unscharf angezeigt. Nur Benutzer, die bereits bezahlt haben, können sie sehen.",
	"Name change": "Namensänderung",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Wenn Sie Ihren Namen oder Nicknamen ändern möchten, Kontaktieren Sie bitte unser Support-Team und wir werden es innerhalb von 24 Stunden tun.",
	"See all images": "Alle Bilder anzeigen",
	"Unlock all private photos forever for just 20 credits": "Schalten Sie alle privaten Fotos für immer für nur 20 Credits frei",
	"Unlock forever all private pictures": "Schalten Sie für immer alle privaten Bilder frei",

	"This email address is not registered, please sign up to log in.": "Diese Email-Adresse ist nicht registriert, bitte melden Sie sich an, um sich einzuloggen.",
	"Congratulations, your account is now active.": "Herzlichen Glückwunsch, Ihr Konto ist jetzt aktiv.",
	"Complete one last step and have fun!": "Schließen Sie einen letzten Schritt ab und haben Sie Spaß!",
	"OR": "ODER",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Wir haben Ihnen eine E-Mail mit einem Link zur Aktivierung Ihres für die nächsten 12 Stunden gültigen Kontos gesendet an:",
	"If you haven\'t received it in your inbox or ": "Wenn Sie es in Ihrem Posteingang oder ",
	"or you want to modify the email address, click on the button below.": "nicht erhalten haben oder Sie möchten die E-Mail-Adresse ändern, klicken Sie auf die Schaltfläche unten.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Wir haben Ihnen eine E-Mail gesendet, um Ihr Konto zu bestätigen. Gültig für die nächsten 12 Stunden:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Wenn Sie sie in Ihrer Mailbox oder in Ihrem Spamordner nicht finden können, können Sie sie weiterleiten oder bearbeiten",
	"Resend or change email": "E-Mail erneut senden oder ändern",
	"Please use the same email you registered.": "Bitte verwenden Sie die gleiche E-Mail, die Sie registriert haben.",
	"Connect with Google": "Verbinden Sie sich mit Google",
	"Connect with Yahoo": "Anmelden mit Yahoo",
	"Connect with Microsoft": "Verbinden Sie sich mit Hotmail",
	"Or turn back": "Zurück",
	"Complete your registration": "Vervollständigen Sie die Anmeldung",

	"It will not be shared": "Wird nicht geteilt",
	"It will never be shared. 100% Privacy": "Es wird nicht geteilt. 100% Privatsphäre",

	"Unlock all private pictures forever.": "Schalte private Fotos für immer frei.",
	"Unlock": "Entsperren",

	"You will be redirected in a second": "Sie werden in wenigen sekunden umgeleitet",

	"Card payment": "Kreditkarte",
	"Bank transfer": "Banküberweisung",
	"Prepaid card": " Prepaid Kreditkarte",
	"SofortBanking": "Sofortüberweisung",

	"Error": "Fehler",
	"page not found": "Seite nicht gefunden",
	"Back to homepage": "Zurück zur Startseite",

	"per month": "pro monat",
	"week": "woche",
	"{count} week": "{count} woche",

	"Your profile has been blocked": "Ihr profil wurde blockiert",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "Um unseren Usern eine bessere Benutzererfahrung zu bieten, blockieren wir Profile, die 21 Tage nach ihrer Einstellung keine Nachrichten gesendet haben.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "Um Ihr Profil nicht zu verlieren und alle Funktionen wieder nutzen zu können, müssen Sie ein Paket Credits erwerben.",
	"Restore access to:": "Zugriff erneut auf:",
	"All messages": "Alle Nachrichten",
	"Users search": "Suche nach Usern",
	"Your contacts": "Ihre Kontakte",
	"All favourites": "Alle Favoriten",
	"All pictures": "Alle Bilder",
	"Send Icebreakers": "Senden Sie Icebreakers",
	"Unblock Profile": "Profil entsperren",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Diese Website verwendet eigene Cookies und Kennungen, sowohl wie von Drittanbietern, um die Zugänglichkeit zu verbessern und unseren Datenverkehr zu analysieren. Wenn Sie weiter surfen, gehen wir davon aus, dass Sie deren Verwendung akzeptieren. Weitere Informationen finden Sie in unserer",
	"Cookies Policy": "Cookie-Richtlinie",

	"DATE GUARANTEE": "DATE GARANTIE",
	"Take advantage of our Date guarantee": "Nutzen Sie die Vorteile unserer Date-Garantie",
	"No date? Money Back!": "Kein Date? Geld zurück!",
	"No risk": "Kein Risiko",
	"Guarantee of success": "Erfolgsgarantie",
	"Terms and Conditions": "Allgemeine Geschäftsbedingungen",

	"Boost your visibility": "Erhöhen Sie Ihre Sichtbarkeit",
	"SAVE": "SPAREN",
	"Add 3 months premium": "Fügen Sie 3 Monate Premium-Mitgliedschaft hinzu",
	"Add {months} months premium": "Fügen Sie {months} Monate Premium-Mitgliedschaft hinzu",

	"We guarantee you a real date.": "Wir garantieren ihnen ein echtes date.",
	"YES, I WANT A DATE": "JA, ICH WILL EIN DATE",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Bedingungen der Date-Garantie. Um alle Rückerstattungsbedingungen zu erfüllen, müssen Sie ein vollständig ausgefülltes Profil für einen Zeitraum von mindestens sechs Monaten und Ihre persönliche Beschreibung in Ihrem Profil haben. Ihr Profil muss während dieses Zeitraums mindestens ein genehmigtes Bild enthalten. Darüber hinaus müssen Sie jede Woche mindestens sieben verschiedene Mitglieder in Ihrer Nähe (Radius von 100 km) innerhalb Ihrer Altersspanne (-5/+5 Jahre) kontaktieren. Wenn Sie alle oben genannten Bedingungen erfüllen und Sie immer noch kein Date finden konnten, erstatten wir Ihnen alle Zahlungen, die Sie während der Laufzeit dieses Zeitraums geleistet haben, vollständig zurück. Diese Garantie gilt erst 12 Monate nach Ihrer Registrierung. Viel Glück!",

	"You have {count} days premium left": "Sie haben {count}-Tage-Premium übrig",
	"Check our special offer before deleting your account!": "Schauen Sie sich unser Sonderangebot an, bevor Sie Ihr Konto löschen!",
	"Delete my account": "Meinen Account löschen",
	"Free": "Kostenlos",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Premium-, Elite und VIP Pakete sind automatisch erneuerbar und das bedeutet, dass sie sich nach dem Ablauf automatisch erneuern werden.",
	"and can confirm that I am 18 years or older.": "und bestätige, dass ich älter als 18 Jahre bin.",

	"COMPLETE YOUR PURCHASE": "SCHLIEßEN SIE IHREN KAUF AB",
	"Payment details": "Zahlungsdetails",
	"Total": "Gesambetrag",
	"3-digit number on the back of your credit card": "3-stellige Nummer auf der Rückseite Ihrer Kreditkarte",
	"MM / YY": "MM / JJ",
	"MM": "MM",
	"YY": "JJ",
	"Name on card": "Name auf der Karte",
	"Charges will appear discreetly as tpmpsup.com": "Gebühren werden diskret als tpmsup.com  angezeigt",
	"Buy Now": "Jetzt Kaufen",
	"Premium package 3 Months": "Premium-Paket 3 Monate",

	"This purchase was declined. Please check your details and try again.": "Dieser Kauf wurde abgelehnt. Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut.",
	"Your purchase was succesful.": "Ihr Kauf war erfolgreich.",

	"SPECIAL OFFER": "SONDERANGEBOT",
	"Unlock your account with our special offer": "Entsperren Sie Ihr Konto mit unserem Sonderangebot",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Nutzen Sie unser Sonderangebot, um Ihr Profil freizuschalten und alle Funktionen wiederherzustellen.",

	"card_number_require": "Kreditkartennummer ist erforderlich",
	"card_number_invalid": "Ungültige Kreditkartennummer",
	"card_name_require": "Der Name des Kreditkarteninhabers ist erforderlich",
	"card_name_invalid": "Ungültiger Name des Kreditkarteninhabers",
	"card_cvc_require": "CVC ist erforderlich",
	"card_cvc_invalid": "CVC ist ungültig",
	"card_expiry_require": "Das Ablaufdatum der Kreditkarte ist erforderlich",
	"card_expiry_invalid": "Das Ablaufdatum der Kreditkarte ist ungültig",

	"Private photo": "Privates Foto",
	"Search for someone now": "Suchen Sie jetzt nach jemandem",
	"Edit": "Bearbeiten",

	"Locked Album": "Gesperrtes Album",
	"Get credits to unlock all albums": "Holen Sie sich Credits, um alle Alben freizuschalten",
	"picture ::: pictures": "foto ::: fotos",
	"Open": "Öffnen",

	"Receive notifications": "Benachrichtigungen erhalten",
	"I want to be notified when I receive a message": "Ich möchte benachrichtigt werden, wenn ich eine Nachricht erhalt",
	"No": "Nein",
	"Yes": "Ja",

	"logout_confirm_title": "Ausloggen", 
	"Are you sure you want to log out?": "Möchten Sie sich wirklich abmelden?",

	"Your payment has been cancelled": "Ihre Zahlung wurde storniert",
	"Something went wrong, please try again in 10 minutes": "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es in 10 Minuten erneut",

	"Please accept the terms and conditions to continue": "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen, um fortzufahren",
	"By checking this box and clicking continue I accept the": "Durch Aktivieren dieser Schaltfläche und Klicken auf Weiter akzeptiere ich die",
	"rg_terms": "AGBs",
	"rg_privacy": "Privacidad",
	"rg_cookies": "die Cookies",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "und ich bin mir der Verwendung von fiktiven Profilen auf der Website bewusst und bestätige, dass ich volljährig bin.",

	"Cookie": "Cookie",
	"Responsible Party": "Verantwortlicher",
	"Category": "Kategorie",
	"Cookie Name": "Name",
	"Purpose": "Zweck",
	"Lifespan": "Lebensdauer",
	"Type of Cookie": "Cookie-Art",
	"Strictly necessary": "Unbedingt erforderlich",
	"Functional": "Funktional",
	"Performance": "Performance",
	"Advertising": "Advertising",
	"cookie-policy": "cookies-richtlinie",
	"cookie policy": "Cookies-Richtlinienseite",

	"We Care About Your Privacy": "Ihre Privatsphäre ist uns wichtig",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Diese Website verwendet eigene, technische Cookies und Cookies von Drittanbietern, um die Navigationsleistung zu verbessern und das Besucherverhalten zu analysieren, um uns und unseren Marketingpartnern dabei zu helfen, Kampagnenergebnisse und personalisierte Werbung zu messen. Indem Sie auf \„Alle akzeptieren\" klicken, stimmen Sie allen diesen Cookies zu.",
	"Accept All": "Alle akzeptieren",
	"Cookies Settings": "Cookie-Einstellungen",
	"About your privacy": "Über Ihre Privatsphäre",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "Wir verwenden Cookies für Traffic-Balancing, Sitzungsidentifikation, Analysen und Marketing-Attribution. Sie können in diesem Panel wählen, ob Sie an einem oder allen von ihnen teilnehmen möchten oder nicht.",
	"You will be able to change your set up at any time from our :cookies_policy page": "Sie können Ihre Einstellungen jederzeit auf unserer :cookies_policy ändern.",
	"Accept all": "Alle akzeptieren",
	"Manage Consent Preferences": "Einwilligungseinstellungen verwalten",
	"Technical (Mandatory)": "Technische Cookies (unbedingt erforderlich)",
	"Performance (Monitor website performance)": "Leistungscookies (Website-Leistung überwachen)",
	"Functional (User experience improvement)": "Funktionale Cookies (Verbesserung der Benutzererfahrung)",
	"Advertising (Marketing measurement)": "Werbung (Marketingmessung)",
    "Advertising (Personalization)": "Werbung (Personalisierung)",
	"Save Selection": "Auswahl speichern",
	"Reject All": "Alle ablehnen",

	"We and our partners process data to provide:": "Wir und unsere Partner verarbeiten Daten, um Folgendes bereitzustellen:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Nutzen Sie präzise Geolokalisierungsdaten. Scannen Sie aktiv die Geräteeigenschaften zur Identifizierung. Informationen auf einem Gerät speichern und/oder darauf zugreifen. Personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessung, Zielgruppeneinblicke und Produktentwicklung.",
	"List of partners": "Liste der Partner",
	"Cookie Policy": "Cookie-Richtlinie",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Diese Cookies werden im Zusammenhang mit der Verfolgung von Aktionen im Zusammenhang mit Werbung verwendet. Diese Cookies merken sich beispielsweise, dass Sie {name} besucht haben und können verwendet werden, um Ihnen auf einer anderen Website eine personalisierte Werbung anzuzeigen. Bitte überprüfen Sie die folgenden Links der Plattformen, auf denen wir für uns werben, Google, Microsoft und Twitter. für mehr Informationen",
	"Delete cookies": "Cookies",
	"Save selection": "Auswahl speichern",
	"Reject all": "Alle ablehnen",

	"{name} hasn't replied to you yet. Send another message!": "{name} hat dir noch nicht geantwortet. Schreib ihr noch eine Nachricht",
	"Validate my Ice-breaker": "Meinen Eisbrecher bestätigen",

	"Distance": "Entfernung",
	"now": "jetzt",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "Ab dem 1. Juni 2023 sind Zahlungen mit PaySafeCard nicht mehr möglich.",

	"Dear User,": "Lieber Nutzer,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Vielen Dank für Ihr Interesse, unserer Website beizutreten. Derzeit ist die Registrierung für Männer jedoch geschlossen, um ein Geschlechterverhältnis von etwa 50/50 Männern und Frauen aufrechtzuerhalten.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Wenn Sie dennoch ein Date wünschen, klicken Sie auf die Schaltfläche unten und probieren Sie eine der besten Online-Websites aus.",
	"Try Now": "Versuchen Sie es jetzt",

	"Your account has been deleted.": "Ihr Account wurde gelöscht.",
	"According to your choices, our algorithms have found a better option for you.": "Unser Algorithmus hat entsprechend Ihrer Auswahl eine viel bessere Option für Sie gefunden.",
	"See More": "Mehr sehen",

	"10 credits for you!": "10 Credits für Sie!",
	"Get 3 free Icebreakers": "Erhalten Sie 3 kostenlose Eisbrecher",
	"Free Icebreakers": "Kostenlose Eisbrecher",
	"Congratulations, you have received 3 Icebreakers!": "Herzlichen Glückwunsch, Sie haben 3 Eisbrecher erhalten!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay funktioniert nur mit dem Safari-Browser. Bitte öffnen Sie Ihren Safari-Browser und melden Sie sich bei Ihrem Konto an, um mit dieser Zahlung fortzufahren.",

	"No nudity": "Keine Nacktheit",
	"Nudity allowed": "Nacktheit erlaubt",

	"Edit profile": "Profil bearbeiten",
	"Online": "Online",
	"Step": "Schritt",
	"Completed": "Fertig",
	"Help": "Hilfe",
	"or Drag and drop": "oder Drag & Drop",

	"You will be able to send messages in": "Sie können Nachrichten senden in",
	"Send": "Senden",
	"Success": "Erfolg",
	"Start a conversation with {name}": "Beginnen Sie ein Gespräch mit {name}",
	"Failed": "Fehlgeschlagen",
	"Congratulations, your account has been successfully created.": "Herzlichen Glückwunsch, Ihr Konto wurde erfolgreich erstellt.",
	"Contact our support": "Kontaktieren Sie unseren Support",
	
	"About": "Über",
	"Scale": "Anpassen",
	"Rotate": "Drehen",

	"No favorites added": "Keine Favoriten hinzugefügt"
	
}
