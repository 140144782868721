export default {
    "Membership": "status",
    "MEMBERSHIP": "PŘEDPLATNÉ",
    "BRONZE": "ZÁKLADNÍ",
    "after_here": "",
    "CLICK TO ACTIVATE - desktop": "AKTIVUJTE KLIKNUTÍM",
    "card_number_require": "Číslo karty je povinné",
    "card_number_invalid": "Číslo karty je neplatné",
    "card_name_require": "Jméno držitele karty je povinné",
    "card_name_invalid": "Jméno držitele karty je neplatné",
    "card_cvc_require": "CVC je povinné",
    "card_cvc_invalid": "CVC je neplatné",
    "card_expiry_require": "Datum platnosti karty je povinné",
    "card_expiry_invalid": "Datum platnosti karty je povinné",
    "Your payment has been cancelled": "Vaše platba byla zrušena",
    "Something went wrong, please try again in 10 minutes": "Něco se nepovedlo, prosím zkuste to znovu za 10 minut",
    "Connect with Yahoo": "Zaregistrujte se pomocí Yahoo",
    "Connect with Microsoft": "Připojit se s Hotmail",
    "Or turn back": "Jít zpět",
    "Complete your registration": "Dokončit vaší registraci",
    "rg_terms": "Všeobecné podmínky a pravidla",
    "rg_privacy": "Zásady ochrany osobních údajů",
    "rg_cookies": "Cookies",
    "Cookie": "Cookie",
    "Responsible Party": "Odpovědná strana",
    "Category": "Kategorie",
    "Cookie Name": "Jméno",
    "Purpose": "Záměr",
    "Lifespan": "Životnost",
    "Type of Cookie": "Typ Cookie",
    "Strictly necessary": "Nezbytně nutné",
    "Functional": "Funkční",
    "Performance": "Výkon",
    "Advertising": "Reklama",
    "cookie policy": "Zásady používání souborů Cookie",
    "We Care About Your Privacy": "Záleží nám na vašem soukromí",
    "Accept All": "Přijmout vše",
    "Cookies Settings": "Nastavení souborů cookie",
    "About your privacy": "O vašem soukromí",
    "You will be able to change your set up at any time from our :cookies_policy page": "Své nastavení budete moci kdykoli změnit na naší stránce :zásady_používání_souborů_cookies",
    "Accept all": "Přijmout vše",
    "Manage Consent Preferences": "Spravovat souhlas a preference",
    "Technical (Mandatory)": "Technické (povinné)",
    "Performance (Monitor website performance)": "Výkon (sledování výkonu webu)",
    "Functional (User experience improvement)": "Funkční (zlepšování zkušeností uživatelů)",
    "Advertising (Marketing measurement)": "Reklama (merketingová měření)",
    "Advertising (Personalization)": "Reklama (personalizace)",
    "Save Selection": "Uložit výběr",
    "Reject All": "Odmítnout vše",
    "We and our partners process data to provide:": "My a naši partneři zpracováváme údaje za účelem poskytování:",
    "List of partners": "Seznam partnerů",
    "Cookie Policy": "Zásady používání souborů Cookie",
    "Delete cookies": "Vymazat Cookies",
    "Save selection": "Uložit výběr",
    "Reject all": "Odmítnout vše",
    "Already a member?": "Už jste členem?",
    "Login": "Přihlásit se",
    "Log out": "Odhlásit se",
    "Your current status": "Váš aktuální status",
    "BASIC": "ZÁKLADNÍ",
    "Basic": "Základní",
    "Bronze": "Bronzový",
    "Elite": "Elitní",
    "VIP": "VIP",
    "Join Us": "Přidejte se k nám",
    "Find people near you": "Najděte lidi ve svém okolí",
    "Join now": "Přidej se nyní",
    "I am a": "Jsem",
    "Email": "E-mail",
    "It will not be published": "Toto nebude zveřejněno",
    "E-mail address": "E-mailová adresa",
    "SEARCH NOW": "Vyhledat nyní",
    "I confirm that I am over 18 years old": "Potvrzuji, že je mi více než 18 let",
    "Information": "Informace",
    "SUBSCRIPTION": "PŘEDPLATNÉ",
    "Other": "Jiný",
    "All fields are required": "Všechna pole jsou povinná",
    "If you did not find a suitable answer please contact us": "Pokud jste nenašli vhodnou odpověď, kontaktujte nás",
    "Will help us to identify you": "Pomůže nám vás identifikovat",
    "Invalid Username": "Neplatné uživatelské jméno",
    "Subject": "Předmět",
    "Tell us how can we help you": "Řekněte nám, jak vám můžeme pomoci",
    "Please enter subject": "Prosím, zadejte předmět",
    "Please enter at least 10 characters": "Prosím, zadejte alespoň 10 znaků",
    "Connected to your account": "Připojeno k vašemu účtu",
    "Repeat Email": "Zopakujte e-mail",
    "Emails did not match": "E-maily se neshodují",
    "Message": "Zpráva",
    "No Recent Activity": "Žádná nedávná aktivita",

    "Home": "Domů",
    "Contact": "Kontakt",
    "Support": "Podpora",
    "Blog": "Blog",
    "Affiliates": "Přidružené společnosti",
    "Cookies": "Cookies",
    "Privacy": "Soukromí",
    "FAQ": "FAQ",
    "Terms": "Podmínky",

    "/login": "/login",
    "/contact": "/contact",
    "/support": "/support",
    "/affiliates": "/affiliates",
    "/privacy": "/soukromi",
    "/terms-and-conditions": "/podminky",
    "/cookie-policy": "/cookie-policy",
    "/subscription/exit": "/subscription/exit",
    "/purchase-confirmation": "/purchase-confirmation",
    "/page/:page_name": "page/:page_name",
    "/dashboard": "/dashboard",
    "/mobile-dashboard": "/mobile-dashboard",
    "/activate-profile/:token": "/activate-profile/:token",
    "/edit-profile": "/edit-profile",
    "/profile/:name": "/profile/:name",
    "/my-picture": "/my-picture",
    "/messages/inbox": "/messages/inbox",
    "/messages/trash": "/messages/trash",
    "/messages/send/:name": "/messages/send/:name",
    "/visitor": "/visitor",
    "/favorite/my": "/favorite/my",
    "/favorite/others": "/favorite/others",
    "/search": "/search",
    "/search-result": "/search-result",
    "/settings": "/settings",
    "/validate-new-email-address/:token": "/validate-new-email-address/:token",
    "/subscription": "/subscription",
    "/credits": "/credits",
    "/elite": "/elite",
    "/faq": "/faq",
    "/delete-account": "/delete-account",
    "/exclusive-promotion": "/exclusive-promotion",
    "/account-deleted": "/account-deleted",
    "/logged-out": "/logged-out",

    "privacy": "soukromi",
    "terms-and-conditions": "podminky",
    "about-us": "about-us",
    "cookie-policy": "cookie-policy",

    "Enter username or Email": "Zadejte uživatelské jméno nebo e-mail",
    "Enter Password": "Zadejte heslo",
    "Username": "Uživatelské jméno",
    "Nickname": "Přezdívka",
    "Password": "Heslo",
    "I forgot my password": "Zapomněl jsem heslo",
    "Password Reminder": "Připomenutí hesla",
    "Enter you email": "Zadejte váš e-mail",
    "Your email address": "Vaše e-mailová adresa",
    "Don't have an account?": "Nemáte účet?",
    "REGISTER": "ZAREGISTROVAT",
    "Password sent to your email": "Heslo bylo zasláno na váš e-mail",
    "This account has not been validated yet": "Tento účet ještě nebyl ověřen",
    "LOGIN USING YOUR CREDENTIALS": "PŘIHLASTE SE POMOCÍ VAŠICH PŘIHLAŠOVACÍCH ÚDAJŮ",
    "FORGOT YOUR PASSWORD?": "ZAPOMENUTÉ HESLO?",
    "Login failed": "Přihlášení se nepodařilo",
    "Username or password is incorrect": "Uživatelské jméno nebo heslo je nesprávné",
    "We're sorry!": "Je nám to líto!",
    "You're almost done!": "Máte téměř hotovo!",
    "Please fill your additional account information": "Vyplňte prosím další informace ve vašem účtu",
    "Just one step left!": "Zbývá již jen jeden krok!",
    "Activate your account now": "Aktivujte svůj účet nyní.",
    "Please confirm your e-mail address": "Potvrďte prosím vaši e-mailovou adresu",
    "Please fill in the following form to join {site_name} for free": "Chcete-li se zdarma připojit ke stránce {site_name}, vyplňte prosím následující formulář",
    "CONTINUE": "POKRAČOVAT",
    "By clicking on the continue button I agree with the": "Kliknutím na tlačítko pokračovat, souhlasím s ",
    "terms": "podmínky",
    "and cookies": "a soubory cookies",
    "This site is protected by reCaptcha and the Google": "Tato stránka je chráněna pomocí reCaptcha a platí Google",
    "Privacy Policy": "Zásady ochrany osobních údajů",
    "and": "a",
    "Terms of Service": "Smluvní podmínky.",
    "Personal description": "Osobní popis",
    "optional": "nepovinné",
    "FINALIZE": "DOKONČIT",
    "We have just sent you a confirmation e-mail at": "Právě jsme vám zaslali potvrzovací e-mail na ",
    "Click here to verify your email": "Klikněte sem pro ověření e-mailu.",
    "CLICK TO ACTIVATE": "KLIKNUTÍM AKTIVOVAT",
    "VERIFY MY EMAIL": "OVĚŘIT MŮJ EMAIL",
    "Not received any email yet?": "Ještě jste neobdrželi žádný e-mail?",
    "Please check your": "Zkontrolujte prosím vaši",
    "spam": "složku spam",
    "box and wait at least 10 minutes or re-send activation": "a počkejte alespoň 10 minut anebo znovu odešlete aktivaci.",
    "Enter Nickname": "Zadejte přezdívku",
    "Enter Your Name": "Zadejte vaše jméno",
    "Password Required": "Heslo je povinné",
    "Enter valid Email": "Zadejte platnou e-mailovou adresu",
    "Please enter at least 5 characters": "Zadejte prosím minimálně 5 znaků",
    "Enter strong password": "Zadejte silné heslo",
    "Email not available": "E-mail není k dispozici",
    "Nickname not available": "Přezdívka není k dispozici",
    "Maximum 4 number allowed": "Povolena jsou maximálně 4 čísla",
    "Name should not contain any space, number or special character": "Jméno by nemělo obsahovat mezeru, číslo ani speciální znaky",
    "Nickname should not contain any space or special characters": "Přezdívka by neměla obsahovat mezeru ani speciální znaky",
    "Your activation mail has been sent": "Váš aktivační e-mail byl odeslán",
    "Complete your profile in a minute and start contacting people": "Vyplňte svůj profil během minuty a začněte kontaktovat lidi",
    "Complete your profile": "Vyplňte váš profil",
    "Create Profile": "Vytvořit profil",
    "Your description": "Váš popis",
    "Confirm your Email": "Potvrďte váš e-mail",
    "Check your email to complete your registration": "Zkontrolujte váš e-mail, abyste mohli dokončit vaši registraci",
    "If you can not find it look it up in the spam or": "Pokud ho nemůžete najít, zkontrolujte váš spam nebo",
    "If you have not received it select one of the following options": "Pokud jste jej neobdrželi, vyberte jednu z následujících možností",
    "junk folder": "nevyžádaná pošta",
    "Change Email": "Změnit e-mail",
    "Resend Email": "Znovu odeslat e-mail",
    "Resend": "Odeslat znovu",
    "New Email": "Nový e-mail",
    "Your activation key is invalid": "Váš aktivační klíč není platný",
    "Name": "Jméno",
    "Marital Status": "Rodinný stav",
    "Date of Birth": "Datum narození",
    "Country": "Stát",
    "Location": "Poloha",
    "Province": "Kraj",
    "City": "Město",
    "Save Changes": "Uložit změny",
    "Select from list": "Vyberte ze seznamu",
    "Day": "Den",
    "Month": "Měsíc",
    "month": "měsíc",
    "Year": "Rok",
    "Prefer not to say": "Raději neuvádět",
    "Only profiles with photo": "Pouze profily s fotografií",
    "This field is required": "Toto pole je povinné",
    "Please update your profile": "Prosím, zaktualizujte váš profil",
    "Profile successfully updated": "Profil byl úspěšně aktualizován",
    "Characters left": "Zbývá znaků",
    "Image attached": "Přiložen obrázek",
    "WITH PICTURE": "S FOTOGRAFIÍ",
    "all age groups": "všechny věkové skupiny",
    "EDIT DESCRIPTION": "UPRAVIT POPIS",
    "DESCRIPTION": "POPIS",
    "FEATURING": "ZOBRAZENÍ",
    "Please Select": "Prosím vyberte",
    "Emails do not match": "E-mailové adresy se neshodují",
    "Your request was succesfully sent!": "Vaše žádost byla úspěšně odeslána!",
    "Or send us a message directly": "Nebo nám pošlete zprávu přímo",
    "My Profile": "Můj profil",
    "Add Photos": "Přidat fotografie",
    "Messages": "Zprávy",
    "Favorites": "Oblíbené",
    "Search": "Vyhledat",
    "Settings": "Nastavení",
    "Visits": "Návštěvy",
    "Likes": "Lajky",
    "Vip Members": "VIP členové",
    "Account activation successful": "Aktivace účtu proběhla úspěšně",
    "My chats": "Moje chaty",
    "Quick Search": "Rychlé vyhledávání",
    "More Options": "Více možností",
    "Looking for": "Hledám",
    "Age": "Věk",
    "Search for": "Hledat",
    "All age groups": "Všechny věkové skupiny",
    "Popular Members": "Populární členové",
    "New Members": "Noví členové",
    "Find a Match": "Najít kdo se k vám hodí ",
    "Recent Activity": "Nedávná aktivita",
    "Add Photo": "Přidat fotografii",
    "Back": "Zpět",
    "Become Elite": "Stát se Elitním členem",
    "Upgrade": "upgradovat",
    "likes": "lajky",
    "EDIT MY PROFILE": "UPRAVIT MŮJ PROFIL",
    "CONFIGURATION": "KONFIGURACE",
    "ONLINE SUPPORT": "ONLINE PODPORA",
    "Trash": "Koš",
    "Delete Selected": "Vymazat vybrané",
    "No Messages Available": "Nejsou k dispozici žádné zprávy",
    "No Messages selected": "Nejsou vybrané žádné zprávy",
    "Back to inbox": "Zpět do doručené pošty",
    "Back to messages": "Zpět do zpráv",
    "Select all": "Vybrat vše",
    "Deselect all": "Odznačit vše",
    "Next Page": "Další stránka",
    "Prev Page": "Předchozí stránka",
    "Type your message here": "Vaši zprávu napište zde",
    "Send message": "Odeslat zprávu",
    "Send Icebreaker": "Odeslat Icebreaker",
    "VIEW PROFILE": "PROHLÉDNOUT PROFIL",
    "DELETE": "VYMAZAT",
    "Chat with": "Chatovat s",
    "Chat": "Chat",
    "My Favorites": "Moje oblíbené",
    "No likes": "Žádné lajky",
    "Favorite added successfully": "Úspěšně přidáno do oblíbených",
    "Favorite deleted successfully": "Úspěšně vymazáno z oblíbených",
    "Visit Profile": "Navštívit profil",
    "My Profile Visitors": "Návštěvníci mého profilu",
    "No Visitors": "Žádní návštěvníci",
    "My pictures": "Moje fotografie",
    "See my public profile": "Podívat se na můj veřejný profil",
    "Profile photo": "Profilová fotografie",
    "Profile Picture": "Profilový obrázek",
    "2MB or lower": "2MB nebo méně",
    "Nudes not allowed in this category.": "Nahé fotografie nejsou v této kategorii povoleny.",
    "PHOTO ALBUM": "FOTOALBUM",
    "Allowed images 2 megabytes or less": "Povoleny jsou fotografie o velikosti 2 megabyty nebo menší",
    "Add picture": "Přidat obrázek",
    "Main reasons for rejection": "Hlavní důvody zamítnutí",
    "Are you sure?": "Jste si jisý?",
    "Are you sure you want to delete this image?": "Opravdu chcete vymazat tento obrázek?",
    "Close": "Zavřít",
    "Confirm": "Potvrdit",
    "Attaching image": "Nahrávání obrázku",
    "Pictures of underage people": "Fotografie nezletilých osob",
    "Personal data is visible": "Osobní údaje jsou viditelné",
    "Fake or stolen pictures": "Falešné nebo odcizené fotografie",
    "Different gender to profile": "Jiné pohlaví než, které je uvedeno v profilu",
    "Group pictures": "Skupinová fotografie",
    "Weapons, drugs, violence": "Zbraně, drogy, násilí",
    "No people visible": "Není zobrazen žádný člověk",
    "Info": "Info",
    "We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Vaše fotografie nyní kontrolujeme. Až projdou procesem ověření, zobrazí se ostatním uživatelům.",
    "Profile": "Profil",
    "yrs": "let",
    "years": "let",
    "yr from": "od ",
    "Physique": "Postava",
    "Photos": "Fotografie",
    "No Photos": "Žádné fotografie",
    "About Me": "O mně",
    "Characteristics": "Vlastnosti",
    "Prefers": "Preference",
    "Relationship": "Vztah",
    "Shape": "Forma",
    "DESCRIBE YOURSELF": "POPIŠTE SE",
    "GENERAL": "OBECNĚ",
    "(Will be verified)": "(Bude ověřeno)",
    "Sex": "Pohlaví",
    "Seeking": "Hledám",
    "Finish your profile": "Dokončete váš profil",
    "Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Vítejte! Protože je váš profil nový, poskytneme mu maximální viditelnost, jakmile jej dokončíte.",
    "Advanced Search": "Pokročilé vyhledávání",
    "OPEN TO": "OTEVŘENÝ (ČEMU)",
    "PREFERENCES": "PREFERENCE",
    "CHARACTER": "CHARAKTER",
    "Search Result": "Výsledky vyhledávání",
    "No Member found": "Nebyl nalezen žádný uživatel",
    "Change Search": "Změnit vyhledávání",
    "Hair Color": "Barva vlasů",
    "Eye Color": "Barva očí",
    "Body Type": "Postava",
    "Height": "Výška",
    "Weight": "Váha",
    "Smoker": "Kuřák/kuřačka",
    "Ethnicity": "Etnická příslušnost",
    "From": "Od",
    "To": "Do",
    "Change E-mail address": "Změnit e-mailovou adresu",
    "Your email address has been successfully updated": "Vaše e-mailová adresa byla úspěšně změněna",
    "Account Status": "Status účtu",
    "Cancel": "Zrušit",
    "Delete Account": "Vymazat účet",
    "Notifications": "Oznámení",
    "Receive all notifications individually": "Dostávat všechna oznámení jednotlivě",
    "Receive one daily email will all my notifications": "Dostávat jeden e-mail denně se všemi mými oznámeními",
    "Receive a weekly email with all my notifications": "Dostávat každý týden e-mail se všemi mými oznámeními",
    "I don\'t want to receive any notifications": "Nechci dostávat žádná oznámení",
    "(we will send you a verification email)": "(pošleme vám ověřovací e-mail)",
    "Current Password": "Současné heslo",
    "New Password": "Nové heslo",
    "Enter valid email": "Zadejte platnou e-mailovou adresu",
    "Repeat New Password": "Zopakujte nové heslo",
    "Save Settings": "Uložit nastavení",
    "Passwords should match": "Hesla by se měla shodovat",
    "Password length should be minimum 5 characters": "Heslo by mělo obsahovat alespoň 5 znaků",
    "I want to receive notifications by email": "Chci dostávat oznámení e-mailem",
    "Status": "Status",
    "Renewal": "Obnovení",
    "Auto renewal Canceled": "Automatické obnovení zrušeno",
    "Delete profile": "Vymazat profil",
    "Successfully updated": "Úspěšně aktualizováno",
    "Current account status": "Současný status účtu",
    "Are you sure you want to cancel your subscription?": "Opravdu chcete zrušit předplatné?",
    "Your subscription has been successfully canceled and will not be auto-renewed": "Vaše předplatné bylo úspěšně zrušeno a nebude automaticky obnovováno",
    "*Active subscriptions deleted this way will not be reimbursed": "*Aktivní předplatné smazané tímto způsobem nebudou proplaceny",
    "Are you sure you want to delete * your account? Your information will be permanently erased.": "Opravdu chcete smazat * svůj účet? Vaše informace budou trvale vymazány",
    "Settings successfully updated": "Nastavení bylo úspěšně aktualizováno",
    "Disable Notification": "Zakázat oznámení",
    "Canceled Auto-Renewal": "Automatické obnovení bylo zrušeno",
    "Upgrade your status": "Upgradujte svůj status",
    "Enjoy our exclusive benefits and make new contacts": "Užívejte si naše exkluzivní výhody a navazujte nové kontakty",
    "Users Online": "Uživatelů online",
    "Discreet Billing. {site_name} will not appear in the invoice": "Diskrétní vyúčtování. Název {site_name} se na účtu nezobrazí",
    "Month ::: Months": "Měsíce ::: Měsíců",
    "3 days trial": "3 denní zkušební verze",
    "Try 3 days": "Vyzkoušejte 3 denní zkušební verzi",
    "Select": "Vybrat",
    "Send messages for 3 consecutive days": "Posílejte zprávy 3 po sobě jdoucí dny",
    "Only one trial subscription per user": "Pouze jedno předplatné zkušební verze na uživatele",
    "for": "pro",
    "For full access to all details of events, including how to cancel it,": "Chcete-li získat úplný přístup ke všem podrobnostem o událostech, včetně toho, jak je zrušit,",
    "click here": "klikněte zde",
    "for terms and conditions,": "na všeobecné podmínky a pravidla",
    "CURRENT PLAN": "AKTUÁLNÍ PLÁN",
    "MY BENEFITS": "MOJE VÝHODY",
    "{discount} cheaper": "{discount} levnější",
    "save": "uložit",
    "Create my profile": "Vytvořit můj profil",
    "Read messages": "Přečíst zprávy",
    "Add to Favorites": "Přidat do Oblíbených",
    "Make yourself visible to other users by adding them to favorites": "Zviditelněte se pro ostatní uživatele tím, že je přidáte do Oblíbených",
    "Photo Album": "Fotoalbum",
    "No fake pictures allowed": "Nejsou povoleny žádné falešné fotografie",
    "Album pictures": "Fotografie v albu",
    "Show yourself and increase your possibilities": "Ukažte se a rozšiřte své možnosti",
    "Detailed profile search": "Podrobné vyhledávání profilu",
    "Find exactly what you are looking for": "Najděte přesně to, co hledáte",
    "See other user's pics": "Prohlížejte fotky ostatních uživatelů",
    "Choose your favorite": "Vyberte si vaše oblíbené",
    "Reply to and send messages": "Odpovídejte na zprávy a posílejte je",
    "Make direct contact with up to five users per day": "Navažte přímý kontakt až s pěti uživateli denně",
    "Be shown first in searches": "Zobrazujte se jako první ve vyhledávání",
    "Your profile will be featured before the rest of the users": "Váš profil bude zobrazen před ostatními uživateli",
    "Make direct contact with unlimited users": "Navažte přímý kontakt s neomezeným počtem uživatelů",
    "Choose payment method": "Vyberte způsob platby",
    "LESS THAN": "MÉNĚ NEŽ",
    "PER DAY": "DENNĚ",
    "DISCRETION": "DISKRÉTNOST",
    "Our website name will not be disclosed": "Název našeho webu nebude prozrazen",
    "Our name will not be disclosed": "Naše jméno nebude prozrazeno",
    "Choose your subscription": "Vyberte si vaše předplatné",
    "Recommended": "Doporučené",
    "Most Popular": "Nejoblíbenější",
    "Secure payments with": "Bezpečné platby s",
    "Your purchase has been successful": "Váš nákup proběhl úspěšně",
    "Become a": "Staňte se ",
    "PREMIUM MEMBER": "PRÉMIOVÝM ČLENEM",
    "And contact": "a kontaktujte",
    "more people": "více lidí",
    "upgrade": "upgradovat",
    "Boost": "Podpořit",
    "boost": "podpořte",
    "your profile": "váš profil",
    "Start chatting and meet more people": "Začněte chatovat a poznejte více lidí",
    "And get": "A získejte",
    "more views": "více shlédnutí",
    "Now +3 extra Credits with each purchase": "Nyní +3 extra kredity ke každému nákupu",
    "Best Seller": "Nejprodávanější",
    "Select your Package": "Vyberte si váš balíček",
    "Less than {amount} per credit": "Méně než {amount} za jeden kredit",
    "Less than": "Méně než",
    "per credit": "za jeden kredit",
    "Free credits": "kredit zdarma",
    "per purchase": "za nákup",
    "Chat now": "Chatujte nyní",
    "Get Credits": "Získejte kredit",
    "Credit": "Kredit",
    "Credits": "Kreditů",
    "My credits": "Mé kredity",
    "{count} Credits left": "{count} zbývajících kreditů",
    "Icebreaker": "Icebreaker",
    "Icebreaker today": "dnešní Icebreaker",
    "With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Pořízením balíčku si uživatel zakoupí balíček kreditů, které lze použít k odesílání zpráv. Poměr kreditů ke zprávě je deset ku jedné, to znamená, že uživatel musí mít na svém účtu alespoň deset kreditů, aby mohl odeslat jednu zprávu. Cena za odeslání Icebreakeru je 2 kredity.",
    "Trial pack": "Zkušební balíček",
    "The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Icebreaker lze použít pouze k zahájení konverzace nebo jako odpověď na jiný Icebreaker.Prémiové,",
    "Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Elitní a VIP balíčky jsou automaticky obnovitelné, což znamená, že se po vypršení platnosti automaticky obnoví.",
    "and can confirm that I am 18 years or older.": "a potvrzuji, že jsem starší 18 let.",
    "Changes can take some minutes to update.": "Uložení změn může trvat několik minut.",
    "Only one trial pack per user allowed": "Je povolen pouze jeden zkušební balíček na jednoho uživatele",
    "Used": "Použitý",
    "Exclusive Promotion": "Exkluzivní propagace",
    "Account Deleted": "Účet vymazán",
    "Log out Successful": "Odhlášení proběhlo úspěšně",

    "Do you like FREE LIVE SEX?": "Máte rádi SEX NAŽIVO ZDARMA?",
    "Enter this code to enjoy a 10% discount:": "Vložte tento kód a získejte 10% slevu:",
    "Discount redeemable for your first online purchase. Expires in 2 hours.": "Slevový kód pro první nákup je platný následujících 6 hodin.",

    "Has added you to favorites": "Si vás přidal do oblíbených",
    "Is looking at your profile": "Si prohlíží váš profil",
    "See profile": "Prohlédnout profil",
    "See message": "Přečíst zprávu",
    "I think I am in love": "Myslím, že jsem zamilovaná",
    "Hey! I'm winking at you": "Hej! Mrkám na tebe",
    "Some flowers for you!": "Kytička pro tebe!",
    "Sending you a kiss": "Posílám ti pusu",
    "Surprise, it\\'s me!": "Překvápko, to jsem já!",
    "Hi, how are you?": "Ahoj, jak je?",
    "Let's get naughty": "Pojďme si zazlobit",
    "Appear high up on searches": "Zobrazení vysoko ve vyhledávání",
    "Distinctive badge that increases your visibility": "Výrazný odznak, který zvyšuje vaši viditelnost",
    "5 Free Icebreakers a day": "5 Icebreakerů zdarma denně",
    "In addition to this, a voucher for a 15% Webcam discount": "K tomu ještě voucher na 15% slevu na Webcam",
    "Succesful purchase": "Úspěšný nákup",
    "Yearly Package": "Roční balíček",
    "Package": "Balíček",
    "Buy Now": "Koupit nyní",
    "Exclusive offer": "Exkluzivní nabídka",
    "Welcome": "Vítejte",
    "Mary from {domain_name} support": "Marie z podpory {domain_name}",
    "Welcome to {domain_name}. We hope you find what you are looking for.": "Vítejte na {domain_name}. Doufáme, že najdete to co hledáte.",
    "Here are some Pro Tips to be more successful and meet people:": "Zde je několik profesionálních tipů, jak být úspěšnější a seznamovat se s lidmi:",
    "And of course, if you like somebody send a message and ask for a date!": "A hlavně, pokud se vám někdo líbí, napište mu zprávu a pozvěte ho na rande!",
    "If you need any extra help, please contact our": "Pokud potřebujete s něčím pomoci, kontaktujte naši ",
    "support": "zákaznickou podporu.",
    "Try your luck": "Zkuste své štěstí",
    "Private picture": "Soukromá fotografie",
    "Name change": "Změna jména",
    "See all images": "Zobrazit všechny obrázky",
    "Unlock all private photos forever for just 20 credits": "Odemkněte navždy všechny soukromé fotografie za pouhých 20 kreditů",
    "Unlock forever all private pictures": "Odemknout navždy všechny soukromé fotografie",
    "Complete one last step and have fun!": "Dokončete poslední krok a bavte se!",
    "OR": "NEBO",
    "We sent you an email with a link to activate your account valid for the next 12 hours to:": "Poslali jsme vám e-mail s odkazem na aktivaci vašeho účtu platný následujících 12 hodin na adresu:",
    "If you haven\'t received it in your inbox or ": "Pokud jste email neobdrželi do doručené pošty a prohlédli jste i",
    "or you want to modify the email address, click on the button below.": "a není tam nebo chcete upravit emailovou adresu, klikněte na tlačítko níže",
    "We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Poslali jsme vám e-mail pro ověření vašeho účtu, e-mail bude platný po dobu následujících 12 hodin:",
    "If you can\'t find it in your inbox or spam, you can edit or resend it": "Pokud jej nemůžete najít ve své doručené poště nebo ve spamu, můžete e-mail změnit nebo odeslat znovu ",
    "Resend or change email": "Změnit e-mail nebo ho poslat znovu",
    "Connect with Google": "Připojit se s Google",
    "It will not be shared": "Nebude sdílen",
    "Unlock": "Odemknout",
    "You will be redirected in a second": "Za vteřinu budete přesměrován",
    "Card payment": "Platba platební kartou",
    "Bank transfer": "Bankovní převod",
    "Prepaid card": "Předplacená karta",
    "SofortBanking": "SofortBanking",
    "Error": "Chyba",
    "page not found": "stránka nenalezena",
    "Back to homepage": "Zpět na domovskou stránku",
    "per month": "na měsíc",
    "week": "týden",
    "{count} week": "{count} týdnů",
    "Your profile has been blocked": "Váš profil byl zablokován",
    "In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "Abychom mohli nabídnout lepší služby s vyváženým poměrem mužů a žen, je v současné době registrace pro muže uzavřena.",
    "Restore access to:": "Obnovit přístup k:",
    "All messages": "Všechny zprávy",
    "Users search": "Vyhledávání uživatelů",
    "Your contacts": "Vaše kontakty",
    "All favourites": "Všechny oblíbené",
    "All pictures": "Všechny fotografie",
    "Send Icebreakers": "Poslat Icebreaker",
    "Unblock Profile": "Odblokovat profil",
    "Cookies Policy": "Zásady používání souborů cookie",
    "DATE GUARANTEE": "RANDE GARANTOVÁNO",
    "Take advantage of our Date guarantee": "Využijte naši záruku na rande",
    "No date? Money Back!": "Žádné rande? Peníze zpět!",
    "No risk": "Bez rizika",
    "Guarantee of success": "Garance úspěchu",
    "Terms and Conditions": "Všeobecné podmínky a pravidla",
    "Boost your visibility": "Zlepšete svou viditelnost",
    "SAVE": "ULOŽIT",
    "Add 3 months premium": "Přidat 3 prémiové měsíce",
    "Add {months} months premium": "Přidat {months} prémiových měsíců",
    "We guarantee you a real date.": "Garantujeme vám opravdovou schůzku.",
    "YES, I WANT A DATE": "ANO, CHCI RANDE",
    "Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Podmínky garantování rande. Abyste splnili všechny podmínky pro vrácení peněz, musíte mít kompletně vyplněný profil po dobu alespoň šesti měsíců a svůj osobní popis ve svém profilu. Váš profil musí během tohoto období obsahovat alespoň jednu schválenou fotografii. Kromě toho musíte každý týden kontaktovat alespoň sedm různých uživatelů, kteří se nacházejí ve vaší blízkosti (okruh do 100 km) ve vašem věkovém rozmezí (-5/+5 let). Pokud splníte všechny výše uvedené podmínky a stále se vám nepodařilo domluvit si rande, vrátíme vám v plné výši všechny platby, které jste během této doby provedli. Tato záruka je platná pouze po dobu prvních 12 měsíců po vaší registraci. Hodně štěstí!",
    "You have {count} days premium left": "Zbývá vám {count} prémiových dní",
    "Check our special offer before deleting your account!": "Prohlédněte si naši speciální nabídku předtím, než se rozhodnete smazat váš účet",
    "Delete my account": "Vymazat můj účet",
    "Free": "Zdarma",
    "COMPLETE YOUR PURCHASE": "DOKONČETE VÁŠ NÁKUP",
    "Payment details": "Detaily platby",
    "Total": "Celkem",
    "3-digit number on the back of your credit card": "3ciferné číslo na zadní straně vaší platební karty",
    "MM / YY": "MM / YY",
    "MM": "MM",
    "YY": "YY",
    "Name on card": "Jméno uvedené na platební kartě",
    "Premium package 3 Months": "Prémiový balíček 3 měsíce",
    "SPECIAL OFFER": "SPECIÁLNÍ NABÍDKA",
    "Unlock your account with our special offer": "Odemkněte svůj účet díky naší speciální nabídce",
    "Private photo": "Soukromá fotografie",
    "Search for someone now": "Vyhledejte nyní někoho",
    "Edit": "Upravit",
    "Locked Album": "Zamčené album",
    "Get credits to unlock all albums": "Získejte kredity k odemknutí všech alb",
    "picture ::: pictures": "fotografie ::: fotografie",
    "Open": "Otevřít",

    "Receive notifications": "Obdržet oznámení",
    "I want to be notified when I receive a message": "Chci dostávat oznámení o příchozích zprávách",
    "No": "Ne",
    "Yes": "Ano",

	"logout_confirm_title": "Odhlásit se", 
	"Are you sure you want to log out?": "Opravdu se chcete odhlásit?",

    "Please accept the terms and conditions to continue": "Chcete-li pokračovat, přijměte prosím všeobecné podmínky a pravidla",
    "By checking this box and clicking continue I accept the": "Zaškrtnutím tohoto políčka a kliknutím na Pokračovat příjimám",
    "Validate my Ice-breaker": "Ověřit můj Icebreaker",
    "Distance": "Vzdálenost",
    "2mb or lower": "2mb nebo méně",
    "I confirm that over 18 years old": "Potvrzuji, že je mi více než 18 let",
    "I am": "Jsem",
    "Required Data": "Povinné údaje",
    "Continue": "Pokračovat",
    "{name} hasn't replied to you yet. Send another message!": "{name} ti ještě neodpověděl/a. Pošli další zprávu!",
    "This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Tento web používá své vlastní soubory cookie a identifikátory a soubory cookie a identifikátory třetích stran ke zlepšení dostupnosti a analýze provozu. Pokud budete pokračovat v prohlížení, považujeme to za souhlas s jejich používáním. Pro více informací navštivte naše",
    "The fields marked in Red are required. Please fill them to continue.": "Pole označená červeně jsou povinná. Chcete-li pokračovat, vyplňte je.",
	"The fields marked in red are required. Please fill them to continue.": "Pole označená červeně jsou povinná. Chcete-li pokračovat, vyplňte je.",
    
    "This account has been deleted.": "Tento účet byl smazán.",
	"Our algorithm has found a better option in line with your taste.": "Náš algoritmus pro vás našel lepší volbu podle vašeho vkusu.",
	"You will be redirected in a few seconds.": "Za několik vteřin budete přesměrován.",

    "100% Anonymous.": "100% Anonymní.",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "Od 1. Června 2023 nebudou již možné platby s PaySafeCard.",

	"Dear User,": "Drahý uživateli,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Děkujeme za váš zájem připojit se k našemu webu, ale v zájmu zachování vyváženého poměru mezi muži a ženami 50/50 není v současné době registrace mužům povolena.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Pokud přesto chcete rande, klikněte na tlačítko níže a vyzkoušejte jeden z nejlepších webů online.",
    "Try Now": "Vyzkoušet",
    
	"Your account has been deleted.": "Váš účet byl smazán.",
	"According to your choices, our algorithms have found a better option for you.": "Podle vašeho výběru pro vás naše algoritmy našly lepší volbu.",
    "See More": "Vidět víc",

	"10 credits for you!": "10 kreditů pro vás!",
	"Get 3 free Icebreakers": "Získejte 3 ledoborce zdarma",
	"Free Icebreakers": "Ledoborce zdarma",
	"Congratulations, you have received 3 Icebreakers!": "Gratulujeme, obdrželi jste 3 ledoborce!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple pay funguje pouze s prohlížečem Safari. Otevřete si prohlížeč Safari a přihlaste se ke svému účtu, abyste mohli pokračovat v této platbě.",

	"No nudity": "Žádná nahota",
	"Nudity allowed": "Nahota povolena",
        
    "Edit profile": "Upravit profil",
    "Online": "Online",
    "Step": "Další krok",
    "Completed": "Dokončeno",
    "Help": "Nápověda",
    "or Drag and drop": "nebo přetáhnout a pustit",

    "You will be able to send messages in": "Zprávy budete moci posílat ve",
    "Send": "Poslat",
    "Success": "Úspěch",
    "Start a conversation with {name}": "Začněte konverzaci s {name}",
    "Failed": "Nepodařilo se",
    "Congratulations, your account has been successfully created.": "Gratulujeme, váš účet byl úspěšně vytvořen.",
    "Contact our support": "Kontaktujte naši podporu",
	
	"About": "O",
	"Scale": "Upravit",
	"Rotate": "Otočit",

	"No favorites added": "Nebyly přidány oblíbené položky"
    
}
